import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import { PostLoginWrapper } from './PostLoginWrapper';
import { useTranslation } from 'react-i18next';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { getUser } from '../actions/userActions';
import { isValidSerialNumber, patchSensor, getField, putLicenseAuto } from '../actions/fieldsActions';
import { Html5Qrcode } from 'html5-qrcode';
import wizard5 from '../assets/wizard5.png';
import wizard4 from '../assets/wizard4.png';
import { Dialog } from 'primereact/dialog';

export const LinkSensor = () => {
    const [activeIndex] = useState(0);
    const { t } = useTranslation();
    const [tstSerialNumber, setTstSerialNumber] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [scanning, setScanning] = useState(false);
    const [html5QrCode, setHtml5QrCode] = useState(null);
    const { fieldId } = useParams();
    const [scanningButton, setScanningButton] = useState(false);
    const navigate = useNavigate();

    // Estados para la validación y activación del sensor
    const [sensorActivate, setSensorActivate] = useState(false);
    // Indica que ya pasó la validación del serial (ya se comprobó que es un sensor válido)
    const [sensorValidated, setSensorValidated] = useState(false);

    // Estados para el diálogo y polling
    const [dialogVisible, setDialogVisible] = useState(false);
    const [checkingSensor, setCheckingSensor] = useState(false);
    const [estimatedTime, setEstimatedTime] = useState('');
    const dialogVisibleRef = useRef(dialogVisible);

    useEffect(() => {
        dialogVisibleRef.current = dialogVisible;
    }, [dialogVisible]);

    useEffect(() => {
        // Verificamos que el usuario tenga permiso para manipular el field (si se accede mediante fieldId)
        const checkUser = async () => {
            const userData = await getUser();
            const user_id = userData.id;
            const field = await getField(fieldId);
            if (field == null || field.owner.id !== user_id) {
                navigate("/fields");
            }
        };

        if (fieldId) {
            checkUser();
        }

        if (activeIndex === 0 && document.getElementById("reader")) {
            const scanner = new Html5Qrcode("reader");
            setHtml5QrCode(scanner);

            return () => {
                if (scanner.isScanning) {
                    scanner.stop().catch((err) =>
                        console.error("Error al detener el escaneo:", err)
                    );
                }
            };
        }
    }, [activeIndex, fieldId, navigate]);

    const startScan = () => {
        if (html5QrCode && !scanning) {
            setScanning(true);
            setErrorMessage('');

            const screenWidth = window.innerWidth;
            const screenHeight = window.innerHeight;

            html5QrCode
                .start(
                    {
                        facingMode: "environment"
                    },
                    {
                        fps: 10,
                        qrbox: { width: screenWidth * 0.7, height: screenWidth * 0.7 },
                        aspectRatio: screenHeight / screenWidth,
                        width: screenWidth,
                        height: screenHeight
                    },
                    (decodedText) => {
                        setTstSerialNumber(decodedText);
                        html5QrCode
                            .stop()
                            .then(() => {
                                setScanning(false);
                                setScanningButton(false);
                            })
                            .catch((err) =>
                                console.error("Error al detener el escaneo:", err)
                            );
                    }
                )
                .then(() => {
                    setScanningButton(true);
                })
                .catch((err) => {
                    console.error(`Error inicializando el escaneo: ${err}`);
                    setErrorMessage(
                        'Error inicializando el escaneo. Por favor, inténtalo de nuevo.'
                    );
                    setScanning(false);
                    setScanningButton(false);
                });
        }
    };

    const stopScan = () => {
        if (html5QrCode && scanning) {
            html5QrCode
                .stop()
                .then(() => {
                    setScanning(false);
                    setScanningButton(false);
                })
                .catch((err) =>
                    console.error("Error al detener el escaneo:", err)
                );
        }
    };

    // Validamos el serial ingresado
    const validateSerialNumber = async () => {
        setErrorMessage('');
        const isValid = await isValidSerialNumber(tstSerialNumber);
        if (typeof isValid === 'string') {
            setTimeout(() => {
                setErrorMessage(t(`link_sensor.${isValid}`));
            }, 100);
        } else {
            const sensorId = isValid.results[0].id;
            if (fieldId) {
                await patchSensor(sensorId, fieldId);
            }
            await putLicenseAuto(sensorId);
            if (isValid.results[0].is_activated) {
                navigateAfterValidation();
            } else {
                setSensorValidated(true);
            }
        }
    };

    // Función para realizar el polling y comprobar manualmente el estado del sensor
    const handleCheckSensorStatus = async () => {
        setDialogVisible(true);
        setCheckingSensor(true);
        let waitedTime = 0;
        const interval = 1000;
        // Tiempo máximo de espera según el prefijo del serial
        const maxWaitTime = tstSerialNumber.startsWith('P24') ? 900000 : 90000;
    
        const updateTimeDisplay = () => {
            const remaining = Math.max(maxWaitTime - waitedTime, 0);
            const totalSeconds = Math.floor(remaining / 1000);
            const minutes = Math.floor(totalSeconds / 60);
            const seconds = totalSeconds % 60;
            setEstimatedTime(
                `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
            );
        };
    
        updateTimeDisplay();
    
        const checkInterval = setInterval(async () => {
            if (!dialogVisibleRef.current) {
                clearInterval(checkInterval);
                setCheckingSensor(false);
                return;
            }
        
            waitedTime += interval;
            updateTimeDisplay();
        
            if (waitedTime % 5000 === 0) {
                const validation = await isValidSerialNumber(tstSerialNumber);
                const activated = typeof validation !== 'string' && validation.results[0].is_activated;
        
                if (activated) {
                    clearInterval(checkInterval);
                    setCheckingSensor(false);
                    setSensorActivate(true);
                    setDialogVisible(false);
                    return;
                }
    
                if (waitedTime >= maxWaitTime) {
                    clearInterval(checkInterval);
                    setCheckingSensor(false);
                    setEstimatedTime('00:00');
                    setDialogVisible(false);
                    setErrorMessage(t('link_sensor.sensor_activation_timeout'));
                }
            }
            
        }, interval);
    };

    // Redirige una vez que el sensor se activa (realizando las peticiones correspondientes)
    const navigateAfterValidation = async () => {
        if (fieldId) {
            navigate(`/field/${fieldId}`);
        } else {
            navigate(`/addLicense`, { state: { sensorNumber: tstSerialNumber } });
        }
    };

    return (
        <PostLoginWrapper
            isCreateField={true}
            stepIndex={activeIndex}
            error={errorMessage}
        >
            <div
                className='flex align-items-center justify-content-center w-11 desktop-max-width'
                style={{ height: !sensorValidated ? '90vh' : '90vh' }}
            >
                <div
                    className='w-full'
                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                >
                    { !sensorValidated ? (
                        <>
                            <Image src={wizard5} alt="wizard5" width={window.innerHeight < 750 ? 250 : 300} />
                            <p className='text-center text-xl mt-5'>{t('link_sensor.wizard6')}</p>
                            <InputText
                                className='w-full mt-5'
                                value={tstSerialNumber}
                                onChange={(e) => setTstSerialNumber(e.target.value)}
                                placeholder={t('link_sensor.wizard6_input_placeholder')}
                            />
                            <Button
                                className='bg-primary w-full mt-3'
                                label={t('link_sensor.wizard6_button_label')}
                                onClick={validateSerialNumber}
                            />
                            <Button
                                link
                                className='text-primary font-semibold w-full mt-5 border-none text-decoration-none'
                                label={t('link_sensor.wizard6_link_label')}
                                onClick={startScan}
                            />
                        </>
                    ) : null }
                    <div id="reader" className={`reader ${scanning ? 'fullscreen' : ''}`}>
                        {scanning && (
                            <Button
                                className="p-button-rounded p-button-danger"
                                icon="pi pi-times"
                                onClick={stopScan}
                                style={{
                                    position: 'absolute',
                                    top: '20px',
                                    left: '20px',
                                    zIndex: 4,
                                    visibility: scanningButton ? 'visible' : 'hidden'
                                }}
                            />
                        )}
                    </div>
                    {/* Sección extra (igual a CreateField) que se muestra tras la validación */}
                    {sensorValidated && (
                        <div
                            className='w-full desktop-max-width'
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                marginTop: '2rem'
                            }}
                        >
                            <Image src={wizard4} alt="wizard4" width={window.innerHeight < 750 ? 250 : 300} />
                            {sensorActivate ? (
                                <p className='text-center text-xl mt-5'>{t('create_field.wizard4_sensor_activated')}</p>
                            ) : (
                                <>
                                    {tstSerialNumber.startsWith('22') ? (
                                        <p className='text-center text-xl mt-5'>{t('create_field.wizard4_22')}</p>
                                    ) : tstSerialNumber.startsWith('23') ? (
                                        <p className='text-center text-xl mt-5'>{t('create_field.wizard4_23')}</p>
                                    ) : tstSerialNumber.startsWith('P24') ? (
                                        <p className='text-center text-xl mt-5'>{t('create_field.wizard4_24')}</p>
                                    ) : null}
                                    <Button
                                        label="Comprobar estado del sensor"
                                        className="mt-3 bg-primary"
                                        onClick={handleCheckSensorStatus}
                                    />
                                </>
                            )}
                            <div
                                className={`relative p-3 flex align-items-center justify-content-center cursor-pointer ${activeIndex === 5 ? 'disabled' : ''}`}
                                style={{ zIndex: 1000, pointerEvents: activeIndex === 4 ? 'none' : 'auto' }}
                                onClick={navigateAfterValidation}
                            >
                                <div className="flex align-items-center gap-2 no-tap-highlight">
                                    <span className="font-medium" style={{ userSelect: 'none', color: activeIndex === 4 ? '#ccc' : 'var(--primary-color)' }}>
                                        {activeIndex === 7 || activeIndex === 8 ? t('create_field.create_field') : activeIndex === 9 ? t('create_field.view_field') : t('create_field.next_step')}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <Dialog
                visible={dialogVisible}
                onHide={() => setDialogVisible(false)}
                className='w-11 p-0 border-round-2xl'
                focusOnShow={false}
                style={{ maxWidth: '500px', color: 'black' }}
                headerStyle={{ color: 'black' }}
                header={t('create_field.wizard4_sensor_status_header')}
                pt={{
                    header: { className: 'border-round-top-2xl' },
                    content: { className: 'border-round-bottom-2xl' }
                }}
            >
                <div className="flex flex-column align-items-center">
                    {checkingSensor ? (
                        <>
                            <p style={{ color: 'black' }} className='w-full'>
                                {`${t('create_field.wizard4_max_time')} ${estimatedTime}`}
                            </p>
                            <div style={{ marginTop: '10px' }} className='loading'></div>
                        </>
                    ) : (
                        <p style={{ color: 'black' }} className='w-full'>
                            {t('create_field.wizard4_sensor_not_activated')}
                        </p>
                    )}
                </div>
            </Dialog>
        </PostLoginWrapper>
    );
};