import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import nematool_logo_RGB from '../assets/nematool_logo_print_CMYK.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faBell, faArrowLeft, faHistory } from '@fortawesome/free-solid-svg-icons'; 
import { Image } from 'primereact/image';
import { Sidebar } from 'primereact/sidebar';
import { logout } from '../actions/userActions';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { ModalGeneric } from './ModalGeneric';
import { Ripple } from 'primereact/ripple';
import { getUnreadNotifications, markNotificationsAsRead } from '../actions/fieldsActions';
import { Badge } from 'primereact/badge';

export const Header = ({ isMain = false, isNotifications = false, isField = false, fieldId = null, goBack = null, isCreateField = false, stepIndex = 0, prevStep }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [visible, setVisible] = useState(false);
    const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
    const [unreadNotifications, setUnreadNotifications] = useState(localStorage.getItem('unreadNotifications') || 0);
    const isNotificationsPage = window.location.pathname === '/notifications';
    useEffect(() => {
        if (!isNotificationsPage) {
            const fetchUnreadNotifications = async () => {
                try {
                    let response = await getUnreadNotifications();
                    if (response > 9) {
                        setUnreadNotifications("+9");
                        localStorage.setItem('unreadNotifications', "+9");
                    } else {
                        setUnreadNotifications(response);
                        localStorage.setItem('unreadNotifications', response);
                    }
                } catch (error) {
                    console.error('Error fetching unread notifications:', error);
                }
            };

            fetchUnreadNotifications();
        }
    }, [isNotificationsPage]);

    const handleSettings = () => {
        setVisible(true);
    };

    const handleGoBack = async () => {
        if (isCreateField) {
            if (stepIndex === 0) {
                navigate(-1);
            } 
            else if (stepIndex === 9) {
                navigate(`/field/${fieldId}`);
            }
            else {
                prevStep();
            }
        } else if (isField) {
            navigate('/fields');
        } else if (isNotifications) {
            await markNotificationsAsRead();
            navigate(-1);
        } else if (goBack == null) {
            navigate(-1);
        } else {
            navigate(goBack);
        }
    };

    const handleNotifications = () => {
        navigate('/notifications', { state: { fieldId: null } });
    };


    const handleHistory = () => {
        navigate('/notifications', { state: { fieldId } });
    };

    const handleUpdateUser = () => {
        navigate('/updateUser');
    };

    const handleLogOut = () => {
        setIsLogoutModalOpen(true);
    };

    const confirmLogOut = () => {
        logout(navigate);
        setIsLogoutModalOpen(false);
    };

    const cancelLogOut = () => {
        setIsLogoutModalOpen(false);
    };

    const redirectToTerms = () => {
        window.location.href = "https://nematool.com/Nematool-UserLicenseAgreement.pdf";
    };

    const redirectToPolicy = () => {
        window.location.href = "https://nematool.com/Nematool-DataPrivacyStatement.pdf";
    };

    const version = process.env.REACT_APP_VERSION;

    return (
        <div className="flex justify-content-between mt-3 w-11">
            <FontAwesomeIcon 
                icon={isMain ? faBars : faArrowLeft} 
                style={isMain ? { 
                    color: 'var(--primary-color)', 
                    fontSize: '24px', 
                    cursor: 'pointer',
                    marginTop: '2px'  
                } : {
                    color: 'var(--primary-color)', 
                    fontSize: '24px', 
                    cursor: 'pointer',
                    marginTop: '2px' 
                }} 
                onClick={isMain ? handleSettings : handleGoBack} 
                className="no-tap-highlight"
            />
            <Image 
                src={nematool_logo_RGB}
                style={{ userSelect: 'none' }}
                alt="Nematool Logo" 
                width={window.innerWidth < 375 ? "180px" : window.innerWidth < 500 ? "200px" : "250px"} 
            />
            <div style={{ position: 'relative' }}>
                <FontAwesomeIcon 
                    icon={faBell} 
                    style={{ 
                        visibility: 'visible', 
                        color: 'var(--primary-color)', 
                        fontSize: '24px', 
                        cursor: 'pointer',
                        outline: 'none',
                        marginTop: '4px'  
                    }} 
                    onClick={isNotificationsPage ? null : handleNotifications}
                />
                {unreadNotifications > 0 && !isNotificationsPage && (
                    <Badge 
                        value={unreadNotifications}
                        severity="danger"
                        style={{
                            position: 'absolute',
                            top: '-8px',
                            right: '-8px',
                            fontSize: '12px',
                            width: '24px',
                            height: '24px',
                            borderRadius: '50%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    />
                )}
            </div>
            <Sidebar
                visible={visible}
                onHide={() => setVisible(false)}
                style={{ width: '280px' }}
                content={({ closeIconRef, hide }) => (
                    <div className="min-h-screen flex relative lg:static surface-ground">
                        <div id="app-sidebar-2" className="surface-section h-screen block flex-shrink-0 absolute lg:static left-0 top-0 z-1 border-right-1 surface-border select-none" style={{ width: '280px' }}>
                            <div className="flex flex-column h-full">
                                <div className="flex align-items-center justify-content-between px-4 pt-3 flex-shrink-0">
                                    <span className="inline-flex align-items-center gap-2">
                                        <span className="font-semibold text-2xl text-primary">{t('settings.title')}</span>
                                    </span>
                                    <span>
                                        <Button type="button" ref={closeIconRef} onClick={(e) => hide(e)} icon="pi pi-times" rounded outlined className="h-2rem w-2rem"></Button>
                                    </span>
                                </div>
                                <div className="overflow-y-auto">
                                    <ul className="list-none pl-2 overflow-hidden">
                                        <li>
                                            <a className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full" onClick={handleUpdateUser}>
                                                <i style={{ color: 'black' }} className="pi pi-user-edit mr-2"></i>
                                                <span style={{ color: 'black' }} className="font-medium">{t('settings.update_user')}</span>
                                                <Ripple />
                                            </a>
                                        </li>
                                        {/* 
                                        <li>
                                            <a className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full" onClick={() => navigate('/verifySensor')}>
                                                <i style={{ color: 'black' }} className="pi pi-key mr-2"></i>
                                                <span style={{ color: 'black' }} className="font-medium">{t('settings.activate_license')}</span>
                                                <Ripple />
                                            </a>
                                        </li>
                                        */}
                                        <li>
                                            <a className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full" onClick={redirectToTerms}>
                                                <i style={{ color: 'black' }} className="pi pi-file mr-2"></i>
                                                <span style={{ color: 'black' }} className="font-medium">{t('settings.terms_and_conditions')}</span>
                                                <Ripple />
                                            </a>
                                        </li>
                                        <li>
                                            <a className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full" onClick={redirectToPolicy}>
                                                <i style={{ color: 'black' }} className="pi pi-file mr-2"></i>
                                                <span style={{ color: 'black' }} className="font-medium">{t('settings.privacy_policy')}</span>
                                                <Ripple />
                                            </a>
                                        </li>
                                        <li>
                                            <a className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full" onClick={handleLogOut}>
                                                <i style={{ color: 'black' }} className="pi pi-sign-out mr-2"></i>
                                                <span style={{ color: 'black' }} className="font-medium">{t('settings.log_out')}</span>
                                                <Ripple />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div style={{ textAlign: 'center', marginTop: 'auto', padding: '1rem', fontSize: '14px', color: 'black', fontWeight: '500' }}>
                                    {t('settings.version')} {version}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            ></Sidebar>

            <ModalGeneric 
                isOpen={isLogoutModalOpen} 
                onClose={cancelLogOut} 
                onConfirm={confirmLogOut}
                message={t('settings.logout_message')}
            />
        </div>
    );
};
