import i18n from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Configuración de i18next
i18n
  .use(resourcesToBackend((language, namespace) => import(`./locales/${language}/${namespace}.json`))) // Para cargar archivos JSON con las traducciones
  .use(LanguageDetector) // Detecta automáticamente el idioma
  .use(initReactI18next) // Inicializa react-i18next
  .init({
    fallbackLng: 'en', // Idioma por defecto
    debug: false, // Habilita el modo debug
    detection: {
      // Prioriza el idioma del navegador
      order: ['navigator'], 
      caches: [], // No guardo en cache
    },
    ns: ['translation', 'countries', 'filtered_countries'],
    interpolation: {
      escapeValue: false, // React ya maneja el escape de valores
    },
  });

export default i18n;