import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmDialog } from 'primereact/confirmdialog';
import pwaSafari from '../assets/pwa-safari.svg';
import pwaShare from '../assets/pwa-share.svg';
import pwaAdd from '../assets/pwa-add.svg';

export const ModalInstall = () => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [message, setMessage] = useState('');
    const [showAccept, setShowAccept] = useState('');

    const isPWAInstalled = () => {
        // Verifica si la app está en modo standalone (instalada) o si está en iOS como PWA
        return window.matchMedia('(display-mode: standalone)').matches ||
               window.navigator.standalone ||
               document.referrer.includes('android-app://');
    };

    useEffect(() => {
        const remindLaterTimestamp = localStorage.getItem('remindLaterInstallTimestamp');
        const currentTime = new Date().getTime();
        const userAgent = navigator.userAgent;
        
        if (/iPad|iPhone|Macintosh|iPod/.test(userAgent)){
            setMessage(`
                <div style="margin-bottom: 1em;">
                    ${t('install_modal.message_ios')}
                </div>
                <div style="display: flex; align-items: center; margin-bottom: 1em;">
                    <img src="${pwaSafari}" alt="Safari Icon" style="margin-right: 10px;" />
                    <p style="margin: 0;">${t('install_modal.message_ios_step_1')}</p>
                </div>
                <div style="display: flex; align-items: center; margin-bottom: 1em;">
                    <img src="${pwaShare}" alt="Share Icon" style="margin-right: 10px;" />
                    <p style="margin: 0;">${t('install_modal.message_ios_step_2')}</p>
                </div>
                <div style="display: flex; align-items: center; margin-bottom: 1em;">
                    <img src="${pwaAdd}" alt="Add Icon" style="margin-right: 10px;" />
                    <p style="margin: 0;">${t('install_modal.message_ios_step_3')}</p>
                </div>
            `);
            setShowAccept('hidden')
        } else {
            setMessage(t('install_modal.message_android'));
        }
        // Solo muestra el modal si la app no está instalada y no se ha pospuesto
        // Si se pospone, se muestra cada 86400000 milisegundos = 1 día
        if (!isPWAInstalled() && (!remindLaterTimestamp || currentTime - remindLaterTimestamp > 86400000)) {
            setIsOpen(true);
        }

        const handleBeforeInstallPrompt = (e) => {
            e.preventDefault();
            setDeferredPrompt(e);
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);

    const handleInstall = () => {
        localStorage.setItem('remindLaterInstallTimestamp', new Date().getTime());
        setIsOpen(false);

        if (deferredPrompt) {
            deferredPrompt.prompt();
            setDeferredPrompt(null);
        }
    };

    const handleRemindMeLater = () => {
        localStorage.setItem('remindLaterInstallTimestamp', new Date().getTime());
        setIsOpen(false);
    };

    return (
        <>
            <ConfirmDialog
                visible={isOpen}
                className="w-11"
                style={{ maxWidth: '500px', color: 'black' }}
                onHide={() => setIsOpen(false)}
                message={<span dangerouslySetInnerHTML={{ __html: message }} style={{ color: 'black' }} />}
                header={<span style={{ color: 'black' }}>{t('install_modal.header')}</span>}
                accept={handleInstall}
                acceptLabel={t('install_modal.accept_label')}
                reject={handleRemindMeLater}
                rejectLabel={t('install_modal.reject_label')}
                messageClassName="mb-4"
                acceptClassName={showAccept}
                defaultFocus="none"
                focusOnShow={false}
                closable={false}
                pt={{
                    header: { className: 'border-round-top-2xl' },
                    footer: {className: 'border-round-bottom-2xl'}
                }}
            />
        </>
    );
};
