import React, { useState } from 'react';
import { AddLicense } from './AddLicense';
import { LinkSensor } from './LinkSensor';

export const License = () => {
    const [step, setStep] = useState('LINK_SENSOR'); // Los posibles valores podrían ser 'LINK_SENSOR' y 'ADD_LICENSE'
    const [sensorNumber, setSensorNumber] = useState('');

    // Esta función se invoca cuando se obtiene el número de sensor en LinkSensor.
    const handleSensorNumberObtained = (number) => {
        setSensorNumber(number);
        setStep('ADD_LICENSE');
    };

    return (
        <>
            {step === 'LINK_SENSOR' && (
                <LinkSensor onSensorNumberObtained={handleSensorNumberObtained} />
            )}
            {step === 'ADD_LICENSE' && (
                <AddLicense sensorNumberLicense={sensorNumber} />
            )}
        </>
    );
};
