import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // Importa useNavigate
import { fetchNotifications, markNotificationsAsRead } from '../actions/fieldsActions';
import { PostLoginWrapper } from './PostLoginWrapper';
import { Card } from 'primereact/card';
import { useTranslation } from 'react-i18next';
import { TabMenu } from 'primereact/tabmenu';

export const Notifications = () => {
    const [notifications, setNotifications] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const fieldId = useLocation().state.fieldId;

    const navigate = useNavigate();
    const { t } = useTranslation();
    const notificationListRef = useRef(null);

    const CARD_WIDTH = 450;
    const [cardsPerRow, setCardsPerRow] = useState(0);
    const [activeIndex, setActiveIndex] = useState(0);
    const [items, setItems] = useState([
        { label: t('notifications.unread'), value: 'unread' },
        { label: t('notifications.read'), value: 'read' }
    ]);

    useEffect(() => {
        const abortController = new AbortController();
        const getNotifications = async () => {
            setIsLoading(true);
            localStorage.setItem('unreadNotifications', 0);
            await fetchNotifications(fieldId, setNotifications, abortController.signal);
            setIsLoading(false);
        };

        getNotifications();

        return () => {
            abortController.abort();
        };
    }, []);

    useEffect(() => {
        const unreadCount = notifications.filter(notification => !notification.read).length;
        const readCount = notifications.filter(notification => notification.read).length;

        setItems([
            { label: `${t('notifications.unread')} (${unreadCount})`, value: 'unread' },
            { label: `${t('notifications.read')} (${readCount})`, value: 'read' }
        ]);
    }, [notifications, t]);

    useEffect(() => {
        const updateCardsPerRow = () => {
            const containerWidth = notificationListRef.current ? notificationListRef.current.offsetWidth : window.innerWidth;
            const newCardsPerRow = Math.floor(containerWidth / CARD_WIDTH);
            setCardsPerRow(newCardsPerRow);
        };

        updateCardsPerRow();

        window.addEventListener('resize', updateCardsPerRow);

        return () => {
            window.removeEventListener('resize', updateCardsPerRow);
        };
    }, [notifications]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const formattedDate = `${day}/${month}/${year} at ${hours}:${minutes}`;
        return formattedDate;
    };

    const handleTabChange = (e) => {
        const index = e.index !== undefined ? e.index : e;
        setActiveIndex(index);
    };

    const filteredNotifications = notifications.filter(notification => {
        return activeIndex === 0 ? !notification.read : notification.read;
    });

    const paddedNotifications = [...filteredNotifications];
    const remainder = filteredNotifications.length % cardsPerRow;

    if (remainder !== 0) {
        const emptyCardsNeeded = cardsPerRow - remainder;
        for (let i = 0; i < emptyCardsNeeded; i++) {
            paddedNotifications.push({ id: `empty-${i}`, isEmpty: true });
        }
    }

    return (
        <PostLoginWrapper isNotifications={!fieldId}>
            <TabMenu 
                className='w-full' 
                model={items} 
                activeIndex={activeIndex} 
                onTabChange={handleTabChange} 
                pt={{
                    menu: {
                        className: 'w-full flex justify-content-between'
                    },
                    menuitem: {
                        className: 'flex-1 flex justify-content-center'
                    },
                    action: {
                        className: 'w-full flex justify-content-center',
                        style: {
                            margin: '0',
                            paddingLeft: '5px',
                            paddingRight: '5px'
                        }
                    }
                }}
            />
            <div
                className='w-full mt-4 desktop-notifications-list mobile-notifications-list'
                style={{ display: 'flex', alignItems: 'center' }}
                ref={notificationListRef}
            >
                {isLoading ? (
                    <div style={{ marginTop: '135px' }} className="loading"></div>
                ) : (
                    paddedNotifications.map((notification, index) => {
                        if (notification.isEmpty) {
                            return <div key={notification.id} className='w-11' style={{ visibility: 'hidden', maxWidth: cardsPerRow === 0 || cardsPerRow === 1 ? '100%' : cardsPerRow === 2 ? '48%' : cardsPerRow === 3 ? '32%' : '23.9%', marginLeft: '10px', marginRight: '10px' }} />;
                        }

                        return (
                            <Card
                                key={index}
                                style={{ 
                                    opacity: !fieldId && notification.read ? 0.7 : 1,
                                    borderRadius: '10px',
                                    border: 'solid',
                                    borderRadius: '10px',
                                    borderWidth: '1px',
                                    borderColor: '#DEE2E6',
                                    boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.3)',
                                    maxWidth: cardsPerRow === 0 || cardsPerRow === 1 ? '100%' : cardsPerRow === 2 ? '48%' : cardsPerRow === 3 ? '32%' : '24%',
                                    minHeight: '200px'
                                }}
                                onClick={async () => {
                                    if (!fieldId) {
                                        await markNotificationsAsRead();
                                        navigate(`/field/${notification.field_id}`);
                                    }
                                }} 
                                className='w-11 mb-3 p-3'
                            >
                                {notification.type === 'sensor_activated' || notification.type === 'sensor_deactivated' ? (
                                    <div>
                                        <p className='font-medium text-color-secondary mt-0'>
                                            {t(`notifications.${notification.type}`)}
                                        </p>
                                        <p className='font-medium text-color-secondary'>{formatDate(notification.created)}</p>
                                        <p className='text-color-secondary mb-0'>{t(`notifications.${notification.type}_body_1`)} {notification.sensor_name} {t(`notifications.${notification.type}_body_2`)} {notification.field_name}</p>
                                    </div>
                                ) : (
                                    <div>
                                        <p className='font-medium text-color-secondary mt-0'>
                                            {t('notifications.field')} {notification.field_name}: {t(notification.type.includes('alert') ? 'notifications.treatment' : 'notifications.preparation')}
                                        </p>
                                        <p className='font-medium text-color-secondary'>{formatDate(notification.created)}</p>
                                        <p className='text-color-secondary mb-0'>{t(`notifications.${notification.type}`)}</p>
                                    </div>
                                )}
                                
                            </Card>
                        );
                    })
                )}
            </div>
        </PostLoginWrapper>
    );
};
