import React, { useRef, useEffect } from 'react';
import { Image } from 'primereact/image';
import { Toast } from 'primereact/toast';
import { ModalInstall } from './ModalInstall';
import nematool_logo from '../assets/nematool_logo_print_WHITE.svg';

export const PreLoginWrapper = ({ children, error }) => {
    const toast = useRef(null);

    useEffect(() => {
        if (error) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: error, life: 6000 });
        }
    }, [error]);

    return (
        <>
            <ModalInstall />
            <div className="flex flex-column align-items-center w-full" style={{ backgroundColor: 'var(--primary-color)', height: '100vh' }}>
                <div className="flex flex-column align-items-center w-11" style={{ marginTop: window.innerHeight > 750 ? '150px' : '50px', maxWidth: '500px' }}>
                    <Toast ref={toast} position="top-center" className="w-11" />
                <Image className='pb-6' src={nematool_logo} alt="Nematool Logo" width="300px" />
                {children}  {/* Aquí se renderiza el contenido adicional */}
                </div>
            </div>
        </>
    );
};
