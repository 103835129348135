import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Html5Qrcode } from 'html5-qrcode';
import { putLicense, isValidSerialNumber } from '../actions/fieldsActions';
import { PostLoginWrapper } from './PostLoginWrapper';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useTranslation } from 'react-i18next';

export const AddLicense = () => {
    const { t } = useTranslation();
    const [licenseCode, setLicenseCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const sensorNumber = location.state?.sensorNumber;
    const fieldId = location.state?.fieldId;
    
    const validateLicense = async () => {
        try {
            setErrorMessage('');
            const sensor = await isValidSerialNumber(sensorNumber);
            if (typeof sensor === 'string') {
                setTimeout(() => {
                    setErrorMessage(t(`link_sensor.${sensor}`));
                }, 100);
                return;
            }
            const sensorId = sensor.results[0].id
            const isValid = await putLicense(licenseCode, sensorId);
            if (typeof isValid === 'string') {
                setTimeout(() => {
                    setErrorMessage(t('add_license.not_valid_license_code'));
                }, 100);
            } else {
                if (fieldId) {
                    navigate(`/field/${fieldId}`);
                }
                navigate(`/fields`);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleInputChange = (e) => {
        setLicenseCode(e.target.value);
    };

    return (
        <PostLoginWrapper error={errorMessage}>
            <div className='flex flex-column align-items-center justify-content-center w-11 desktop-max-width' style={{ height: '70vh' }}>
                <p className='text-center text-xl mt-5'>{t('add_license.enter_license_code')}</p>
                <InputText 
                    className='qr-input w-full'
                    type="text" 
                    value={licenseCode} 
                    onChange={handleInputChange} 
                    placeholder={t('add_license.placeholder_license_code')}
                />
                <Button 
                    className='bg-primary w-full mt-3'
                    label={t('add_license.button_label')}
                    onClick={validateLicense}
                />
            </div>
        </PostLoginWrapper>
    );
};
