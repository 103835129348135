import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { PostLoginWrapper } from './PostLoginWrapper';
import { useTranslation } from 'react-i18next';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import Map from './Map';
import { isValidSerialNumber, getCrops, postField, postSensorToField, putLicenseAuto } from '../actions/fieldsActions';
import { Html5Qrcode } from 'html5-qrcode';
import wizard1 from '../assets/wizard1.png';
import wizard2 from '../assets/wizard2.png';
import wizard3 from '../assets/wizard3.png';
import wizard4 from '../assets/wizard4.png';
import wizard5 from '../assets/wizard5.png';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';

export const CreateField = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const { t } = useTranslation();
    const [tstSerialNumber, setTstSerialNumber] = useState('');
    const [sensorId, setSensorId] = useState('');
    const [sensorActivate, setSensorActivate] = useState(false);
    const [fieldName, setFieldName] = useState('');
    const [crop, setCrop] = useState('');
    const [crops, setCrops] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null); 
    const [errorMessage, setErrorMessage] = useState('');
    const [scanning, setScanning] = useState(false);
    const [html5QrCode, setHtml5QrCode] = useState(null);
    const [scanningButton, setScanningButton] = useState(false);
    const [fieldId, setFieldId] = useState('');
    const navigate = useNavigate();
    const [mapKey, setMapKey] = useState(Date.now());

    const [dialogVisible, setDialogVisible] = useState(false);
    const [checkingSensor, setCheckingSensor] = useState(false);
    const [estimatedTime, setEstimatedTime] = useState('');

    const dialogVisibleRef = useRef(dialogVisible);

    useEffect(() => {
        dialogVisibleRef.current = dialogVisible;
      }, [dialogVisible]);

    useEffect(() => {
        if (activeIndex === 1 && document.getElementById("reader")) {
            const scanner = new Html5Qrcode("reader");
            setHtml5QrCode(scanner);

            return () => {
                if (scanner.isScanning) {
                    scanner.stop().catch((err) => console.error("Error al detener el escaneo:", err));
                }
            };
        }

        if (activeIndex === 4) {
            if (window.innerWidth < 500) {
                startScan();
            }
        }

        if (activeIndex === 6) {
            getCrops().then((data) => {
                const sortedCrops = data.results.sort((a, b) => {
                    const cropA = t(`field.crops.${a.id}`).toLowerCase();
                    const cropB = t(`field.crops.${b.id}`).toLowerCase();
                    return cropA.localeCompare(cropB);
                });
                setCrops(sortedCrops);
            });
        }

        if (activeIndex === 6 || activeIndex === 7) {
            setMapKey(Date.now());
        }

    }, [activeIndex]);

    const startScan = () => {
        if (html5QrCode && !scanning) {
            setScanning(true);
            setErrorMessage('');

            const screenWidth = window.innerWidth;
            const screenHeight = window.innerHeight;

            html5QrCode.start(
                { 
                    facingMode: "environment"
                },
                {
                    fps: 10,
                    qrbox: { width: screenWidth * 0.7, height: screenWidth * 0.7 },
                    aspectRatio: screenHeight / screenWidth,
                    width: screenWidth,
                    height: screenHeight
                },
                (decodedText) => {
                    setTstSerialNumber(decodedText);
                    html5QrCode.stop()
                        .then(() => {
                            setScanning(false);
                            setScanningButton(false);
                        })
                        .catch((err) => console.error("Error al detener el escaneo:", err));
                }
            ).then(() => {
                setScanningButton(true);
            }).catch((err) => {
                console.error(`Error inicializando el escaneo: ${err}`);
                setErrorMessage('Error inicializando el escaneo. Por favor, inténtalo de nuevo.');
                setScanning(false);
                setScanningButton(false);
            });
        }
    };

    const stopScan = () => {
        if (html5QrCode && scanning) {
            html5QrCode.stop()
                .then(() => {
                    setScanning(false);
                    setScanningButton(false);
                })
                .catch((err) => console.error("Error al detener el escaneo:", err));
        }
    };

    const checkFieldName = () => {
        setErrorMessage('');
        if (fieldName.length === 0) {
            setTimeout(() => {
                setErrorMessage(t('create_field.wizard7_error'));
            }, 100);
            return false;
        }
        return true;
    }

    const checkCrop = () => {
        setErrorMessage('');
        if (crop.length === 0) {
            setTimeout(() => {
                setErrorMessage(t('create_field.wizard8_error'));
            }, 100);
            return false;
        }
        return true;
    }

    const createField = async () => {
        const field_id = await postField(fieldName, crop, selectedLocation);
        setFieldId(field_id);
        const responseStatus = await postSensorToField(field_id, sensorId);
        if (responseStatus !== 200){
            setErrorMessage("An error occurred while creating the field");
            return;
        }
        await putLicenseAuto(sensorId);
    }

    const nextStep = async () => {
        if (activeIndex === 5) {
            if (!checkFieldName(tstSerialNumber)) {
                return;
            }
        }
        if (activeIndex === 6) {
            if (!checkCrop()) {
                return;
            }
        }
        if (activeIndex === 7) {      
            createField();
            setActiveIndex((prevIndex) => prevIndex + 2);
            return;
        }

        if (activeIndex === 8) {
            setErrorMessage('');
            if (!selectedLocation ||
                selectedLocation.lng === null ||
                isNaN(selectedLocation.lng) ||
                selectedLocation.lat === null ||
                isNaN(selectedLocation.lat)) {
                setTimeout(() => {
                    setErrorMessage(t('create_field.not_valid_coordinates'));
                }, 100);
                return;
            }
            createField();
            setActiveIndex((prevIndex) => prevIndex + 1);
            return;
        }

        if (activeIndex === 9) {
            navigate(`/field/${fieldId}`);
            return;
        }

        if (activeIndex < totalSteps - 1) {
            setActiveIndex((prevIndex) => prevIndex + 1);
        }
    };

    const prevStep = () => {
        if (activeIndex > 0) {
            setActiveIndex((prevIndex) => prevIndex - 1);
        }
    };

    const validateSerialNumber = async () => {
        setErrorMessage('');
        const isValid = await isValidSerialNumber(tstSerialNumber);
        if (typeof isValid === 'string') {
            setTimeout(() => {
                setErrorMessage(t(`create_field.${isValid}`));
            }, 100);
        } else {
            let sensorId = isValid.results[0].id;
            setSensorId(sensorId);
            setSensorActivate(isValid.results[0].is_activated);
            nextStep();
        }
    };

    const handleMapLoad = useCallback((initialPosition) => {
        setSelectedLocation(initialPosition); 
    }, []);

    const handleMapClick = (position) => {
        setSelectedLocation(position); 
    };

    const updateLatitude = (newLat) => {
        setSelectedLocation((prevLocation) => ({
            ...prevLocation,
            lat: newLat
        }));
    };

    const updateLongitude = (newLng) => {
        setSelectedLocation((prevLocation) => ({
            ...prevLocation,
            lng: newLng
        }));
    };

    const handleCheckSensorStatus = async () => {
        setDialogVisible(true);
        setCheckingSensor(true);
        let waitedTime = 0;
        const interval = 1000;
        // Ajustamos el tiempo máximo según el prefijo de tstSerialNumber
        const maxWaitTime =
          tstSerialNumber.startsWith('P24') ? 900000 : 90000;
    
        const updateTimeDisplay = () => {
          const remaining = Math.max(maxWaitTime - waitedTime, 0);
          const totalSeconds = Math.floor(remaining / 1000);
          const minutes = Math.floor(totalSeconds / 60);
          const seconds = totalSeconds % 60;
          setEstimatedTime(
            `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
          );
        };
    
        // Actualizamos la cuenta regresiva de inmediato
        updateTimeDisplay();
    
        const checkInterval = setInterval(async () => {
            if (!dialogVisibleRef.current) {
                clearInterval(checkInterval);
                setCheckingSensor(false);
                return;
            }
        
            waitedTime += interval;
            updateTimeDisplay();
        
            // Realizamos la petición cada 5 segundos
            if (waitedTime % 5000 === 0) {
                const isValid = await isValidSerialNumber(tstSerialNumber);
                const sensorActivated =
                    typeof isValid !== 'string' && isValid.results[0].is_activated;
        
                if (sensorActivated) {
                    clearInterval(checkInterval);
                    setCheckingSensor(false);
                    setSensorActivate(true);
                    setDialogVisible(false);
                    return;
                }

                if (waitedTime >= maxWaitTime) {
                    clearInterval(checkInterval);
                    setCheckingSensor(false);
                    setEstimatedTime('00:00');
                }
            }
            
        }, interval);
    };

    const totalSteps = 9;

    return (
        <PostLoginWrapper
            isCreateField={true}
            fieldId={fieldId}
            stepIndex={activeIndex}
            prevStep={prevStep}
            error={errorMessage}
        >
            <div className='flex align-items-center justify-content-center w-11' style={{ height: '80vh' }}>
                <div className='w-full desktop-max-width' style={{ display: activeIndex === 0 ? 'flex' : 'none', flexDirection: 'column', alignItems: 'center' }}>
                    <Image src={wizard1} alt="wizard1" width={300} />
                    <p className='text-center text-xl mt-5'>{t('create_field.wizard1')}</p>
                </div>
                <div className='w-full desktop-max-width' style={{ display: activeIndex === 1 ? 'flex' : 'none', flexDirection: 'column', alignItems: 'center' }}>
                    <Image src={wizard2} alt="wizard2" width={300} />
                    <p className='text-center text-xl mt-5'>{t('create_field.wizard2')}</p>
                </div>
                <div className='w-full desktop-max-width' style={{ display: activeIndex === 2 ? 'flex' : 'none', flexDirection: 'column', alignItems: 'center' }}>
                    <Image src={wizard3} alt="wizard3" width={300} />
                    <p className='text-center text-xl mt-5'>{t('create_field.wizard3')}</p>
                </div>
                <div className='w-full desktop-max-width' style={{ display: activeIndex === 3 ? 'flex' : 'none', flexDirection: 'column', alignItems: 'center' }}>
                    <Image src={wizard5} alt="wizard5" width={300} />
                    <p className='text-center text-xl mt-5'>{t('create_field.wizard5')}</p>
                </div>
                <div className='w-full desktop-max-width' style={{ display: activeIndex === 4 ? 'flex' : 'none', flexDirection: 'column', alignItems: 'center' }}>
                    <p className='text-center text-xl'>{t('create_field.wizard6')}</p>
                    <InputText className='w-full' value={tstSerialNumber} onChange={(e) => setTstSerialNumber(e.target.value)} placeholder={t('create_field.wizard6_input_placeholder')} />
                    <Button className='bg-primary w-full mt-3' label={t('create_field.wizard6_button_label')} onClick={validateSerialNumber} />
                    <Button link className='text-primary font-semibold w-full mt-5 border-none text-decoration-none' label={t('create_field.wizard6_link_label')} onClick={startScan} />
                    <div id="reader" className={`reader ${scanning ? 'fullscreen' : ''}`}>
                        {scanning && (
                            <Button 
                                className="p-button-rounded p-button-danger" 
                                icon="pi pi-times" 
                                onClick={stopScan}
                                style={{
                                    position: 'absolute',
                                    top: '20px',
                                    left: '20px',
                                    zIndex: 4,
                                    visibility: scanningButton ? 'visible' : 'hidden'
                                }}
                            />
                        )}
                    </div>
                </div>
                <div className='w-full desktop-max-width' style={{ display: activeIndex === 5 ? 'flex' : 'none', flexDirection: 'column', alignItems: 'center' }}>
                    <p className='text-center text-xl'>{t('create_field.wizard7')}</p>
                    <InputText className='w-full' placeholder={t('create_field.wizard7_input_placeholder')} onChange={(e) => setFieldName(e.target.value)} />
                    <p className='w-11 text-sm mt-2'>{t('create_field.wizard7_example')}</p>
                </div>
                <div className='w-full desktop-max-width' style={{ display: activeIndex === 6 ? 'flex' : 'none', flexDirection: 'column', alignItems: 'center' }}>
                    <p className='text-center text-xl'>{t('create_field.wizard8')}</p>
                    <Dropdown 
                        className='w-full' 
                        options={crops} 
                        value={crop} 
                        onChange={(e) => setCrop(e.value)}
                        optionLabel={(option) => t(`field.crops.${option.id}`)}
                        optionValue="id"
                    />
                </div>
                <div className='w-full' style={{ display: activeIndex === 7 ? 'flex' : 'none', flexDirection: 'column', alignItems: 'center' }}>
                    {activeIndex === 7 && (
                        <Map 
                            key={mapKey}
                            onMapClick={handleMapClick} 
                            onMapLoad={handleMapLoad} 
                        />
                    )}
                    <p className='text-center text-xl pt-3 mb-0 underline cursor-pointer' style={{ color: 'var(--primary-color)' }} onClick={() => {setSelectedLocation(null); setActiveIndex(8)}}>{t('create_field.wizard9')}</p>
                </div>
                <div className='w-full' style={{ display: activeIndex === 8 ? 'flex' : 'none', flexDirection: 'column', alignItems: 'center' }}>
                    <p className='text-xl'>{t('create_field.wizard10')}</p>
                    <InputText 
                        className='w-full'
                        placeholder={t('create_field.wizard10_input_1_placeholder')}
                        inputMode="numeric"
                        pattern="^-?[0-9.,]*$"
                        onKeyDown={(e) => {
                            const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'];
                            if (allowedKeys.includes(e.key)) return;
                            
                            if (e.key === '-') {
                                if (e.target.selectionStart !== 0 || e.target.value.includes('-')) {
                                    e.preventDefault();
                                }
                                return;
                            }
                            
                            if (e.key === '.' || e.key === ',') {
                                if (e.target.value.includes('.') || e.target.value.includes(',')) {
                                    e.preventDefault();
                                }
                                return;
                            }
                            
                            if (!/^[0-9]$/.test(e.key)) {
                                e.preventDefault();
                            }
                        }}
                        onChange={(e) => {
                            const normalizedValue = e.target.value.replace(',', '.');
                            updateLatitude(parseFloat(normalizedValue));
                        }}
                    />
                    <InputText 
                        className='w-full mt-3'
                        placeholder={t('create_field.wizard10_input_2_placeholder')}
                        inputMode="numeric"
                        pattern="^-?[0-9.,]*$"
                        onKeyDown={(e) => {
                            const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'];
                            if (allowedKeys.includes(e.key)) return;
                            
                            if (e.key === '-') {
                                if (e.target.selectionStart !== 0 || e.target.value.includes('-')) {
                                    e.preventDefault();
                                }
                                return;
                            }
                            
                            if (e.key === '.' || e.key === ',') {
                                if (e.target.value.includes('.') || e.target.value.includes(',')) {
                                    e.preventDefault();
                                }
                                return;
                            }
                            
                            if (!/^[0-9]$/.test(e.key)) {
                                e.preventDefault();
                            }
                        }}
                        onChange={(e) => {
                            const normalizedValue = e.target.value.replace(',', '.');
                            updateLongitude(parseFloat(normalizedValue));
                        }}
                    />
                </div>
                <div className='w-full desktop-max-width' style={{ display: activeIndex === 9 ? 'flex' : 'none', flexDirection: 'column', alignItems: 'center' }}>
                    <Image src={wizard4} alt="wizard4" width={300} />
                    {sensorActivate ? (
                        <p className='text-center text-xl mt-5'>{t('create_field.wizard4_sensor_activated')}</p>
                    ) : (
                        <>
                            {tstSerialNumber.startsWith('22') ? (
                                <p className='text-center text-xl mt-5'>{t('create_field.wizard4_22')}</p>
                            ) : tstSerialNumber.startsWith('23') ? (
                                <p className='text-center text-xl mt-5'>{t('create_field.wizard4_23')}</p>
                            ) : tstSerialNumber.startsWith('P24') ? (
                                <p className='text-center text-xl mt-5'>{t('create_field.wizard4_24')}</p>
                            ) : null}
                            <Button label="Comprobar estado del sensor" className="mt-3 bg-primary" onClick={handleCheckSensorStatus} />
                        </>
                    )}
                </div>
            </div>
            <Dialog 
                visible={dialogVisible} 
                onHide={() => setDialogVisible(false)} 
                className='w-11 p-0 border-round-2xl'
                focusOnShow={false}
                style={{ maxWidth: '500px', color: 'black' }}
                headerStyle={{ color: 'black' }}
                header={t('create_field.wizard4_sensor_status_header')}
                pt={{
                    header: { className: 'border-round-top-2xl' },
                    content: {className: 'border-round-bottom-2xl'}
                }}
            >
                <div className="flex flex-column align-items-center">
                    {checkingSensor ? (
                        <>
                            <p style={{color: 'black'}} className='w-full'>{`${t('create_field.wizard4_max_time')} ${estimatedTime}`}</p>
                            <div style={{marginTop: '10px'}} className='loading'></div>
                        </>
                    ) : (
                        <p style={{color: 'black'}} className='w-full'>{t('create_field.wizard4_sensor_not_activated')}</p>
                    )}
                </div>
            </Dialog>
            <div className='w-full fixed bottom-0 left-0 w-full bg-white '>
                <div className="flex justify-content-center mb-3 w-full" style={{ position: 'relative', zIndex: 1001 }}>
                    {Array.from({ length: totalSteps }).map((_, index) => (
                        <span
                            key={index}
                            style={{
                                height: '10px',
                                width: '10px',
                                margin: '0 5px',
                                backgroundColor: (activeIndex === 8 || activeIndex === 9 ? activeIndex - 1 : activeIndex) === index ? 'var(--primary-color)' : '#ccc',
                                borderRadius: '50%',
                                display: 'inline-block'
                            }}
                        ></span>
                    ))}
                </div>
                <div
                    className={`w-full p-3 shadow-2 flex align-items-center justify-content-center cursor-pointer ${activeIndex === 5 ? 'disabled' : ''}`}
                    style={{ zIndex: 1000, pointerEvents: activeIndex === 4 ? 'none' : 'auto' }}
                    onClick={nextStep}
                >
                    <div className="flex align-items-center gap-2 no-tap-highlight">
                        <span className="font-medium" style={{ userSelect: 'none', color: activeIndex === 4 ? '#ccc' : 'var(--primary-color)' }}>
                            {activeIndex === 7 || activeIndex === 8 ? t('create_field.create_field') : activeIndex === 9 ? t('create_field.view_field') : t('create_field.next_step')}
                        </span>
                    </div>
                </div>
            </div>
        </PostLoginWrapper>
    );
};