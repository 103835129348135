import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from '@react-google-maps/api';
const gmaps_api_key = process.env.REACT_APP_GMAPS_API_KEY;

const containerStyle = {
    width: '100%',
    height: '400px'
};

const defaultCenter = {
    lat: 0,
    lng: 0
};

const Map = ({ onMapClick, onMapLoad, latitude, longitude, clickable = true, zoom: initialZoom = 3 }) => {
    const [position, setPosition] = useState(null);
    const [clickedPosition, setClickedPosition] = useState(null);
    const [selectedPosition, setSelectedPosition] = useState(null);
    const [zoom, setZoom] = useState(initialZoom);
    const mapRef = useRef(null);
    const { t } = useTranslation(); 

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: gmaps_api_key
    });

    useEffect(() => {
        // Se verifica si se pasaron coordenadas (incluso si son 0)
        if (latitude !== undefined && longitude !== undefined) {
            const manualPosition = { lat: latitude, lng: longitude };
            setPosition(manualPosition);
            onMapLoad(manualPosition);
            setZoom(15);
        } else if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const initialPosition = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };
                    setPosition(initialPosition);
                    onMapLoad(initialPosition);
                    setZoom(15);
                },
                (error) => {
                    // Si ocurre un error, se usa el centro por defecto y se establece el zoom a 12
                    setPosition(defaultCenter);
                    onMapLoad(defaultCenter);
                },
                {
                  maximumAge: 120000,       // Se aceptan posiciones en caché de hasta 120 segundos
                  enableHighAccuracy: true, // Se solicita la mayor precisión posible
                }
            );
        } else {
            setPosition(defaultCenter);
            onMapLoad(defaultCenter);
        }
    }, [latitude, longitude, onMapLoad]);

    const handleMapClick = (event) => {
        if (!clickable) return; // No hacer nada si clickable es false

        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        const newPosition = { lat, lng };
        setClickedPosition(newPosition);
        onMapClick(newPosition);
    };

    const handleMarkerClick = (position, zoom) => {
        /*
        const latOffset = 0.0015;
        setSelectedPosition({
            ...position,
            lat: position.lat + latOffset
        });
        */
    };

    const handleInfoWindowCloseClick = () => {
        setSelectedPosition(null);
    };

    const openInGoogleMaps = (position) => {
        const url = `https://www.google.com/maps?q=${position.lat},${position.lng}`;
        window.open(url, '_blank');
    };

    const handleZoomChanged = () => {
        if (mapRef.current) {
            const map = mapRef.current;
            setZoom(map.getZoom());
        }
    };

    return (
        isLoaded ? (
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={position || defaultCenter}
                zoom={zoom}
                onClick={handleMapClick}
                onLoad={(map) => (mapRef.current = map)}
                onZoomChanged={handleZoomChanged}
                options={{
                    mapTypeId: 'satellite',
                    disableDefaultUI: false,
                    zoomControl: false,
                    streetViewControl: false,
                    mapTypeControl: false,
                    fullscreenControl: false,
                    rotateControl: false,
                }}
            >
                {clickedPosition && (
                    <Marker
                        position={clickedPosition}
                        onClick={() => handleMarkerClick(clickedPosition, zoom)}
                    />
                )}
                {position && !clickedPosition && (
                    <Marker
                        position={position}
                        onClick={() => handleMarkerClick(position, zoom)}
                    />
                )}
                {selectedPosition && (
                    <InfoWindow
                        position={selectedPosition}
                        onCloseClick={handleInfoWindowCloseClick}
                        options={{ disableAutoPan: true }}
                    >
                        <div>
                            <Button onClick={() => openInGoogleMaps(selectedPosition)}>
                                {t('field.open_maps')}
                            </Button>
                        </div>
                    </InfoWindow>
                )}
            </GoogleMap>
        ) : (
            <></>
        )
    )
}

export default Map;
