export const availableLicense = (dateExpired) => {
    if (!dateExpired) {
        return false;
    }
    const date = new Date(dateExpired);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return date > today;
};

export const warningLicense = (dateExpired) => {
    if (!dateExpired) {
        return true;
    }
    const date = new Date(dateExpired);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const diffTime = date - today;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays <= 30;
};

export const expirationDays = (dateExpired) => {
    const date = new Date(dateExpired);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const diffTime = Math.abs(date - today);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
};

export const formatDateLicence = (dateExpired) => {
    if (!dateExpired) {
        return '';
    }
    const date = new Date(dateExpired);
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const dia = date.getDate().toString().padStart(2, '0');
    const mes = (date.getMonth() + 1).toString().padStart(2, '0');
    const año = date.getFullYear();

    if (date <= today) {
        return ``;
    } else {
        return `${dia}/${mes}/${año}`;
    }
};