import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const ModalLicenseExpiration = ({ isOpen, onClose, fieldId,isOwner, licenseInfo }) => {
    const { t } = useTranslation();

    const formatDateLicence = (dateExpired) => {
        if (!dateExpired) {
            if (isOwner) {
                return (
                    <span>
                        {t('license_expiration.not_license_owner')}{' '}
                        <Link 
                            to={`/linkSensor/${fieldId}`} 
                            state={{ fieldId }} 
                            className="text-primary" 
                        >
                            {t('field_card.link_sensor_click')}
                        </Link>
                    </span>
                );
            } else {
                return (
                    <span>
                        {t('license_expiration.not_license_shared')}
                    </span>
                );
            }
        }
        const date = new Date(dateExpired);
        const today = new Date();
        today.setHours(0, 0, 0, 0);
    
        const dia = date.getDate().toString().padStart(2, '0');
        const mes = (date.getMonth() + 1).toString().padStart(2, '0');
        const año = date.getFullYear();
    
        if (date <= today) {
            return (
                <span>
                    {`${t('license_expiration.text_expired_1')} ${dia}/${mes}/${año}. ${t('license_expiration.text_expired_2')}`}
                </span>
            );
        } else {
            const days = Math.ceil((date - today) / (1000 * 60 * 60 * 24));
            if (days <= 30) {
                return (
                    <span>
                        {`${t('license_expiration.text_warning_1')} ${dia}/${mes}/${año}. ${t('license_expiration.text_warning_2')}`}
                    </span>
                );
            }
            return (
                <span>
                    {`${t('license_expiration.text_valid')} ${dia}/${mes}/${año}.`}
                </span>
            );
        }
    };

    const expirationDate = formatDateLicence(licenseInfo?.expiration_license_date);

    return (
        <Dialog 
            header={t('license_expiration.title')} 
            visible={isOpen}
            focusOnShow={false}
            className="w-11 desktop-max-width"
            onHide={onClose} 
            onClick={e => e.stopPropagation()}
            pt={{
                header: { className: 'border-round-top-2xl' },
                content: { className: 'border-round-bottom-2xl' }
            }}
            modal>
            <div>
                <p>
                    {expirationDate}
                </p>
            </div>
        </Dialog>
    );
}; 