import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getField, getCrops, deleteField, getGuests, deleteGuest, fetchNotifications, interpolateData } from '../actions/fieldsActions';
import { getUser } from '../actions/userActions';
import { postNematode, postSolarization, putNematode, putSolarization } from '../actions/modelActions'; 
import { getSignalStatus, getBatteryStatus, getModel, getModelId, getOwner, getColor, getColorFooter, getColorCard, getColorCardFooter } from '../utils/fieldUtils';
import { ModalGeneric } from './ModalGeneric';
import { ModalInterpolate } from './ModalInterpolate';
import { availableLicense, warningLicense, formatDateLicence, expirationDays } from '../utils/functions';
import Map from './Map';
import { Calendar } from 'primereact/calendar';
import { Chart } from 'primereact/chart';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Card } from 'primereact/card';
import { Timeline} from 'primereact/timeline';
import { getTemperatureData, getPredictionModel, getPredictionModelJ2, getSolarizationData, getExcelPredictionModel, getExcelPredictionJ2Model, getExcelSolarizationData, getExcelTemperatureData } from '../actions/temperatureActions';
import 'chartjs-plugin-gradient'; // Importa el plugin
import { PostLoginWrapper } from './PostLoginWrapper';
import { useTranslation } from 'react-i18next';
import { Badge } from 'primereact/badge';
import { Image } from 'primereact/image';
import { ProgressBar } from 'primereact/progressbar';
import { Link } from 'react-router-dom';
import { InputSwitch } from 'primereact/inputswitch';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import well_signal from '../assets/well_signal_black.svg';
import half_signal from '../assets/half_signal_black.svg';
import low_signal from '../assets/low_signal_black.svg';
import not_signal from '../assets/not_signal_black.svg';
import not_battery from '../assets/not_battery_black.svg';
import low_battery from '../assets/low_battery_black.svg';
import half_battery from '../assets/half_battery_black.svg';
import well_battery from '../assets/well_battery_black.svg';
import not_signal_battery from '../assets/not_signal_battery_black.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faDownload, faMapMarkerAlt, faTrash, faCheck, faTimes, faExpand, faWarning, faCircleInfo, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { ModalLicenseExpiration } from './ModalLicenseExpiration';

export const Field = () => {
    const { fieldId } = useParams();
    const navigate = useNavigate(); 
    const [errorMessage, setErrorMessage] = useState('');
    const [fieldData, setFieldData] = useState(null);
    const [crop, setCrop] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userId, setUserId] = useState('');
    const [haveModel, setHaveModel] = useState('');
    const [isInterpolateModalOpen, setIsInterpolateModalOpen] = useState(false);
    const [initDate, setInitDate] = useState(null);
    const [lastDate, setLastDate] = useState(new Date());
    const [chartData1, setChartData1] = useState([]);
    const [chartData2, setChartData2] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingInit, setIsLoadingInit] = useState(true);
    const { t } = useTranslation();
    const [refreshTrigger, setRefreshTrigger] = useState(false);
    const [isLoadingTrigger, setIsLoadingTrigger] = useState(false);
    const [isMapDialogVisible, setIsMapDialogVisible] = useState(false);
    const [showStartModelDialog, setShowStartModelDialog] = useState(false);
    const [showStopNematodeDialog, setShowStopNematodeDialog] = useState(false);
    const [showStopSolarizationDialog, setShowStopSolarizationDialog] = useState(false);
    const [showDeleteFieldDialog, setShowDeleteFieldDialog] = useState(false);
    const [showDownloadDataDialog, setShowDownloadDataDialog] = useState(false);
    const [downloadType, setDownloadType] = useState(null);
    const [isChartModalVisible, setIsChartModalVisible] = useState(false);
    const [address, setAddress] = useState('');
    const [currentChart, setCurrentChart] = useState(1);
    const [notifications, setNotifications] = useState([]);
    const [events, setEvents] = useState([]);
    const [isLicenseModalOpen, setIsLicenseModalOpen] = useState(false);
    const [license_info, setLicenseInfo] = useState(null);
    const [isFirstLoad, setIsFirstLoad] = useState(true);

    useEffect(() => {
        getField(fieldId).then((field) => {
            if (field == null) {
                navigate("/fields");
            }
        })
        const fetchUserData = async () => {
            try {
                const userData = await getUser();
                setUserEmail(userData.email);
                setUserId(userData.id);
                if (fieldId) {
                    const fieldData = await getField(fieldId);
                    setFieldData(fieldData);
                    setLicenseInfo(fieldData.sensors.length > 0 ? fieldData.sensors[0].license_info : null);
                    const cropsData = await getCrops();
                    const cropData = cropsData.results.find(crop => crop.id === fieldData.crop);
                    setCrop(cropData.id);
                } else {
                    console.error("El fieldId es undefined");
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };

        fetchUserData();
    }, [fieldId, refreshTrigger]);


    useEffect(() => {
        let newDateInit = new Date();
        
        if (fieldData) {
            let sevenDaysAgo = new Date();
            sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

            if(fieldData.active_prediction_model){
                newDateInit = new Date(fieldData.active_prediction_model.started.replace('Z', ''));
                setHaveModel('N');
            }
            else if(fieldData.active_solarization){
                newDateInit = new Date(fieldData.active_solarization.started.replace('Z', ''));
                setHaveModel('S');
            }
            else{
                newDateInit = sevenDaysAgo;
                setHaveModel('No model');
            }
        
            setInitDate(newDateInit);
            //setIsLoading(true);
            const timer = setTimeout(() => {
                fetchChartData(fieldData.id, getModelId(fieldData), newDateInit, lastDate);
            }, 1000);
            
            return () => clearTimeout(timer);
        }
    }, [fieldData]);

    const f = fieldData;

    let isAvailableLicense;
    let isWarningLicense;
    let daysExpiredLicense;

    if (f) {
        if (f.sensors.length > 0) {
            isAvailableLicense = availableLicense(f.sensors[0].license_info.expiration_license_date);
            isWarningLicense = warningLicense(f.sensors[0].license_info.expiration_license_date);
            daysExpiredLicense = expirationDays(f.sensors[0].license_info.expiration_license_date);
            if (!isAvailableLicense){
                navigate('/fields');
            }
        }else{
            navigate('/fields');
        }
    }

    let lat, lon;
    if (f && f.area && Array.isArray(f.area.coordinates) && f.area.coordinates.length === 2) {
        lon = f.area.coordinates[0];
        lat = f.area.coordinates[1];
    } else {
        lat = 0;
        lon = 0;
    }
    const ownerEmail = f ? getOwner(f) : '';

    useEffect(() => {
        if (lat && lon) {
            const fetchAddress = async () => {
                try {
                    const response = await axios.get('https://nominatim.openstreetmap.org/reverse', {
                        params: {
                            lat,
                            lon,
                            format: 'json',
                            'accept-language': navigator.language || 'en'
                        }
                    });
                    setAddress(response.data.display_name);
                } catch (error) {
                    console.error('Error fetching address:', error);
                }
            };

            fetchAddress();
        }
    }, [lat, lon]);

    const handleStartModel = () => {
        setShowStartModelDialog(true);
    };

    const handleStartNematode = async () => {
        if (f) {
            await postNematode(f.id);
            if (f.sensors.length > 0) {
                await interpolateData(0, f.sensors[0].id);
            }
            //setIsInterpolateModalOpen(true);
            //setIsLoadingTrigger(true);
            //setIsLoadingInit(true);
            setIsLoadingInit(true);
            setRefreshTrigger(prev => !prev);
        }
    };

    const handleStartSolarization = async () => {
        if (f) {
            await postSolarization(f.id);
            //setIsLoadingTrigger(true);
            setIsLoadingInit(true);
            setRefreshTrigger(prev => !prev);
        }
    };

    const handleStopNematode = () => {
        setShowStopNematodeDialog(true);
    };

    const handleStopSolarization = () => {
        setShowStopSolarizationDialog(true);
    };

    const handleDeleteField = () => {
        setShowDeleteFieldDialog(true);
    };

    const handleMapLoad = (position) => {
        // Puedes realizar otras acciones cuando el mapa se carga
    };

    const handleClickBuyLicense = (sensorNumber) => {
        navigate('/addLicense', { state: { sensorNumber } });
    };

    const handleClickLinkSensor = () => {
        navigate('/link/sensor', { state: { fieldId } });
    };

    const closeInterpolateModal = () => {
        setIsInterpolateModalOpen(false);
        setRefreshTrigger(prev => !prev);
    };

    const formatDateSensor = (date) => {
        const dateSensor = new Date(date.replace('Z', ''));
        let now = new Date(new Date().toLocaleString('en-US', { timeZone: 'GMT' }));

        const diffMs = now - dateSensor;
        const diffMinutes = Math.floor(diffMs / (1000 * 60));
        const diffHours = Math.floor(diffMinutes / 60);
        const diffDays = Math.floor(diffHours / 24);

        if (diffDays > 0) {
            return `${diffDays} días`;
        } else if (diffHours > 0) {
            return `${diffHours} horas`;
        } else {
            return `${diffMinutes} minutos`;
        }
    };

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
    };


    const formatDateChart = (date, interval) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hour = String(date.getHours()).padStart(2, '0');
        if (interval === 'H') {
            return `${day}-${month}-${year} ${hour}:00`;
        } else {
            return `${day}-${month}-${year}`;
        }

    };

    const fetchChartData = async (fieldId, model, initDate, lastDate) => {
        //setIsLoading(true);

        const formattedInitDate = formatDate(initDate);
        const nextDay = new Date(lastDate);
        nextDay.setDate(nextDay.getDate() + 1);
        const formattedLastDate = formatDate(nextDay);
        let modelName = t('field.solarization');
        let modelNameJ2 = "";
        let modelData = [];
        let modelDataJ2 = [];
        let interval = 'H';
        
        if (model && model[0] === "N") {
            interval = "D";
            modelName = t('field.ovicide');
            modelNameJ2 = t('field.larvicide');
            
            // Obtener datos del modelo
            modelData = await getPredictionModel(model[1], formattedInitDate, formattedLastDate);
            modelDataJ2 = await getPredictionModelJ2(model[1], formattedInitDate, formattedLastDate);

            if (events.length == 0){
                const modelStartEvent = [];
                if (fieldData && fieldData.sensors.length > 0 && (fieldData.sensors[0].license_info.nematode_ovicide || fieldData.sensors[0].license_info.nematode_larvicide)){
                    modelStartEvent.push({
                        status: 'start_model',
                        date: formattedInitDate
                    });
                }

                // Agrupar los datos por cada generación y extraer el primer evento de cada tipo
                const uniqueGenerations = [...new Set(modelData.map(entry => entry.generation))];
                
                const eggWarnings = [];
                const eggAlerts = [];
                
                if (fieldData && fieldData.sensors.length > 0 && fieldData.sensors[0].license_info.nematode_ovicide){
                    uniqueGenerations.forEach(gen => {
                        const genEntries = modelData.filter(entry => entry.generation === gen);
            
                        // Procesar notificaciones para huevos: advertencias
                        const genEggWarnings = genEntries
                            .filter(entry => entry.ratio_eggs * 100 >= 80 && entry.ratio_eggs * 100 < 100)
                            .sort((a, b) => new Date(a.date) - new Date(b.date));
                        if (genEggWarnings.length > 0) {
                            eggWarnings.push({
                                status: 'nematode_head_warning_eggs',
                                date: genEggWarnings[0].date,
                                generation: gen
                            });
                        }
                        
                        // Procesar notificaciones para huevos: alertas
                        const genEggAlerts = genEntries
                            .filter(entry => entry.ratio_eggs * 100 >= 100)
                            .sort((a, b) => new Date(a.date) - new Date(b.date));
                        if (genEggAlerts.length > 0) {
                            eggAlerts.push({
                                status: 'nematode_head_alert_eggs',
                                date: genEggAlerts[0].date,
                                generation: gen
                            });
                        }
                    });
                }

                const larvaeWarnings = [];
                const larvaeAlerts = [];
                
                if (fieldData && fieldData.sensors.length > 0 && fieldData.sensors[0].license_info.nematode_larvicide){
                    const uniqueGenerationsJ2 = [...new Set(modelDataJ2.map(entry => entry.generation))];

                    uniqueGenerationsJ2.forEach(gen => {
                        const genEntriesJ2 = modelDataJ2.filter(entry => entry.generation === gen);
                        
                    // Procesar notificaciones para larvas: advertencias
                    const genLarvaeWarnings = genEntriesJ2
                        .filter(entry => entry.ratio_larvicide >= 80 && entry.ratio_larvicide < 100)
                        .sort((a, b) => new Date(a.date) - new Date(b.date));
                    if (genLarvaeWarnings.length > 0) {
                        larvaeWarnings.push({
                            status: 'nematode_head_warning_larvae',
                            date: genLarvaeWarnings[0].date,
                            generation: gen
                        });
                    }
                    
                    // Procesar notificaciones para larvas: alertas
                    const genLarvaeAlerts = genEntriesJ2
                        .filter(entry => entry.ratio_larvicide >= 100)
                        .sort((a, b) => new Date(a.date) - new Date(b.date));
                    if (genLarvaeAlerts.length > 0) {
                        larvaeAlerts.push({
                            status: 'nematode_head_alert_larvae',
                            date: genLarvaeAlerts[0].date,
                                generation: gen
                            });
                        }
                    });
                }

                // Combinar todas las notificaciones y ordenar por fecha descendiente
                const timeline_values = [...eggWarnings, ...eggAlerts, ...larvaeWarnings, ...larvaeAlerts, ...modelStartEvent]
                    .sort((a, b) => new Date(b.date) - new Date(a.date));

                // Actualizar las notificaciones
                setEvents([...timeline_values]);

            }
        }
        else if (model && model[0] === "S") {
            modelData = await getSolarizationData(fieldId, formattedInitDate, formattedLastDate);

            if (events.length == 0){
                

                // Agregar el evento de inicio del modelo
                const modelStartEvent = [{
                    status: 'start_model',
                    date: formattedInitDate
                }];

                const lowEvent = modelData
                    .filter(entry => entry.degrees >= 50 && entry.degrees < 500)
                    .sort((a, b) => new Date(a.date) - new Date(b.date))
                    .slice(0, 1)
                    .map(entry => ({
                        status: 'low',
                        date: entry.date
                    }));

                // Encontrar la primera ocurrencia de cada nivel
                const moderateEvent = modelData
                    .filter(entry => entry.degrees >= 500 && entry.degrees < 1500)
                    .sort((a, b) => new Date(a.date) - new Date(b.date))
                    .slice(0, 1)
                    .map(entry => ({
                        status: 'moderate',
                        date: entry.date
                    }));


                const goodEvent = modelData
                    .filter(entry => entry.degrees >= 1500 && entry.degrees < 3000)
                    .sort((a, b) => new Date(a.date) - new Date(b.date))
                    .slice(0, 1)
                    .map(entry => ({
                        status: 'good',
                        date: entry.date
                    }));

                const excellentEvent = modelData
                    .filter(entry => entry.degrees >= 3000 && entry.degrees < 6000)
                    .sort((a, b) => new Date(a.date) - new Date(b.date))
                    .slice(0, 1)
                    .map(entry => ({
                        status: 'excellent',
                        date: entry.date
                    }));


                const superEvent = modelData
                    .filter(entry => entry.degrees >= 7000)
                    .sort((a, b) => new Date(a.date) - new Date(b.date))
                    .slice(0, 1)
                    .map(entry => ({
                        status: 'super',
                        date: entry.date
                    }));


                // Combinar todos los eventos y ordenar por fecha descendiente
                const timeline_values = [...modelStartEvent, ...lowEvent, ...moderateEvent, ...goodEvent, ...excellentEvent, ...superEvent]
                    .sort((a, b) => new Date(b.date) - new Date(a.date));

                // Actualizar las notificaciones
                setEvents([...timeline_values]);
            }
        }

        const temperatureData = await getTemperatureData(fieldId, interval, formattedInitDate, formattedLastDate);
        const labels = Object.keys(temperatureData).map(dateString => formatDateChart(new Date(dateString), interval));
        const temperatureDataset = Object.values(temperatureData).map(entry => entry.mean);

        let modelDataset = [];
        let modelDatasetJ2 = [];
        let modelDatasetJ2Color = [];
        if (model && model[0] === "N"){  
            modelDataset = null;
            if (modelData && modelData.length > 0){
                modelDataset = labels.map(label => {
                    const modelEntry = modelData.find(entry => formatDateChart(new Date(entry.date), interval) === label);
                    return modelEntry ? modelEntry.ratio_eggs * 100 : null;
                });
            }
            modelDatasetJ2 = labels.map(label => {
                const modelEntry = modelDataJ2.find(entry => formatDateChart(new Date(entry.date), interval) === label);
                return modelEntry ? modelEntry.ratio_larvicide : null;
            });
            modelDatasetJ2Color = labels.map(label => {
                const modelEntry = modelDataJ2.find(entry => formatDateChart(new Date(entry.date), interval) === label);
                return modelEntry ? modelEntry.color : null;
            });
        }
        else if (model && model[0] === "S"){
            modelDataset = labels.map(label => {
                const modelEntry = modelData.find(entry => formatDateChart(new Date(entry.date), interval) === label);
                return modelEntry ? modelEntry.degrees : null;
            });
        }

        const getColor = (value) => {
            if (model && model[0] === "S"){
                return '#8AE640';
            }

            if (value == null) return 'transparent';
            if(value > 130) return '#67AAFB';
            if(value > 100) return '#ED5F55';
            if(value > 80) return '#F6894E';

            if(value == 'blue') return '#67AAFB';
            if(value == 'green') return '#7ECA22';
            if(value == 'orange') return '#F6894E';
            if(value == 'red') return '#ED5F55';

            return '#7ECA22';
        };

        const datasets1 = [
            {
                label: t('field.temperature'),
                fill: false,
                borderColor: '#8A99AD',
                tension: .4,
                data: temperatureDataset,
                yAxisID: 'y',
                backgroundColor: 'transparent',
                pointBackgroundColor: '#8A99AD',
                pointBorderColor: '#8A99AD',
                pointRadius: window.innerWidth < 500 ? 0 : 2,
                pointHoverRadius: window.innerWidth < 500 ? 0 : 5,
                borderWidth: 3,
            }
        ];

        const datasets2 = [...datasets1];

        if (model && modelData.length > 0) {
            let ultimoValor = modelDataset[modelDataset.length - 1];
            let colorUltimo = getColor(ultimoValor);
            datasets1.push({
                label: modelName,
                borderColor: colorUltimo,
                fill: false,
                tension: .4,
                data: modelName === 'Ovicida' ? modelDataset.map(value => (value > 100 ? 100 : value)) : modelDataset.map(value => (value > 7000 ? 7000 : value)),
                yAxisID: 'y1',
                pointBackgroundColor: modelDataset.map(value => getColor(value)),
                pointBorderColor: modelDataset.map(value => getColor(value)),
                pointRadius: window.innerWidth < 500 ? 0 : 2,
                pointHoverRadius: window.innerWidth < 500 ? 0 : 5,
                borderWidth: 3,
                segment: {
                    borderColor: ctx => getColor(modelDataset[ctx.p1DataIndex]),
                    backgroundColor: ctx => `${getColor(modelDataset[ctx.p1DataIndex])}33`
                }
            });

            if (model[0] === "N" && sensorNumber.startsWith('P')){
                let ultimoValorJ2 = modelDatasetJ2Color[modelDatasetJ2Color.length - 1];
                let colorUltimoJ2 = getColor(ultimoValorJ2);
                datasets2.push({
                    label: modelNameJ2,
                    borderColor: colorUltimoJ2,
                    fill: false,
                    tension: .4,
                    data: modelDatasetJ2.map(value => (value > 100 ? 100 : value)),
                    yAxisID: 'y1',
                    pointBackgroundColor: modelDatasetJ2Color.map(value => getColor(value)),
                    pointBorderColor: modelDatasetJ2Color.map(value => getColor(value)),
                    pointRadius: window.innerWidth < 500 ? 0 : 2,
                    pointHoverRadius: window.innerWidth < 500 ? 0 : 5,
                    borderWidth: 3,
                    segment: {
                        borderColor: ctx => getColor(modelDatasetJ2Color[ctx.p1DataIndex]),
                        backgroundColor: ctx => `${getColor(modelDatasetJ2Color[ctx.p1DataIndex])}33`
                    }
                });
            }
        }

        setChartData1({
            labels,
            datasets: datasets1
        });

        setChartData2({
            labels,
            datasets: datasets2
        });

        setIsLoadingInit(false);

        if (!model){
            handleStartModel();
        }
        //setIsLoading(false);
        //setIsLoadingTrigger(false);
    };

    const getLightTheme = () => {
        let multiAxisOptions = {
            animation: {
                duration: 0 // Desactiva la animación
            },
            stacked: false,
            maintainAspectRatio: false,
            aspectRatio: .6,
            plugins: {
                legend: {
                    labels: {
                        usePointStyle: true,
                        boxWidth: 10,
                        boxHeight: 10,
                        padding: 10,
                        color: '#000000',
                        pointStyle: 'circle',
                        generateLabels: (chart) => {
                            const datasets = chart.data.datasets;
                            return datasets.map((dataset, i) => ({
                                text: dataset.label,
                                fillStyle: dataset.borderColor,
                                strokeStyle: dataset.borderColor,
                                lineWidth: 2,
                                hidden: !chart.isDatasetVisible(i),
                                index: i
                            }));
                        }
                    },
                    onClick: (e, legendItem, legend) => {
                        const index = legendItem.index;
                        const ci = legend.chart;
                        const meta = ci.getDatasetMeta(index);
                        meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;
                        ci.update();
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    type: 'linear',
                    display: true,
                    position: 'left',
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    },
                    title: {
                        display: true,
                        text: t('field.temperature_axis'),
                        color: '#495057'
                    }
                },
                ...(haveModel!= "No model" ? {
                    y1: {
                        beginAtZero: true,
                        type: 'linear',
                        display: true,
                        position: 'right',
                        min: 0,
                        max: haveModel === "N" ? 105 : 7700,
                        ticks: {
                            ...(haveModel === "N" ? {
                                stepSize: 5,
                                callback: (value) => {
                                    if (value <= 100) {
                                        return value;
                                    }
                                    return '';
                                }
                            } : {
                                stepSize: 700,
                                callback: (value) => {
                                    if (value <= 7000) {
                                        return value;
                                    }
                                    return '';
                                }
                            }),
                            color: '#495057',
                        },
                        grid: {
                            drawOnChartArea: false,
                            color: '#ebedef'
                        },
                        title: {
                            display: true,
                            text: haveModel === "N" ? t('field.model_nematode_axis') : t('field.model_solarization_axis'),
                            color: '#495057'
                        }
                    }
                } : {})
            }
        };

        return {
            multiAxisOptions
        }
    }
    const { multiAxisOptions } = getLightTheme();

    const downloadData = async (type) => {
        setDownloadType(type);
        setShowDownloadDataDialog(true);
    };

    const downloadModelData = async (model, type) => {
        let response;
        const timezoneOffset = new Date().getTimezoneOffset() * -1 / 60;
        if (model && model[0] === "Nematode active"){
            if (type === 'larvicide'){
                response = await getExcelPredictionJ2Model(f.id, formatDate(initDate), formatDate(lastDate), timezoneOffset);
            } else {
                response = await getExcelPredictionModel(f.id, formatDate(initDate), formatDate(lastDate), timezoneOffset);
            }
        }
        else if (model && model[0] === "Solarization active"){
            response = await getExcelSolarizationData(f.id, formatDate(initDate), formatDate(lastDate), timezoneOffset);
        }
        else{
            return;
        }
        const url = window.URL.createObjectURL(await response.blob());
        const a = document.createElement('a');
        a.href = url;
        a.download = `data-${type}-model.xlsx`;
        document.body.appendChild(a);
        a.click();
        a.remove();
    };

    const downloadTemperatureData = async (model) => {
        let interval = 'H';
        const timezoneOffset = new Date().getTimezoneOffset() * -1 / 60;
        if (model && model[0] === "Nematode active"){
            interval = 'D';
        }
        const response = await getExcelTemperatureData(f.id, interval, formatDate(initDate), formatDate(lastDate), timezoneOffset);
        const url = window.URL.createObjectURL(await response.blob());
        const a = document.createElement('a');
        a.href = url;
        a.download = 'data-temperature.xlsx';
        document.body.appendChild(a);
        a.click();
        a.remove();
    };

    const handleOpenMaps = () => {
        if (lat && lon) {
            const url = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lon}`;
            window.open(url, '_blank');
        } else {
            console.error("Coordenadas no disponibles");
        }
    };

    const getSignalIcon = (signalSensor) => {
        switch (signalSensor) {
            case 'well_signal':
                return well_signal;
            case 'half_signal':
                return half_signal;
            case 'low_signal':
                return low_signal;
            default:
                return not_signal;
        }
    };

    const getBatteryIcon = (batteryStatus) => {
        switch (batteryStatus) {
            case 'not_signal_battery':
                return not_signal_battery;
            case 'no_battery':
                return not_battery;
            case 'low_battery':
                return low_battery;
            case 'half_battery':
                return half_battery;
            default:
                return well_battery;
        }
    };

    let sensorNumber = f && f.sensors.length > 0 ? f.sensors[0].tst_serial_number : 'no_sensor';

    let haveSensor = true;
    if (sensorNumber === 'no_sensor'){
        sensorNumber = t('field_card.no_sensor');
        haveSensor = false;
    }

    let fieldName = f ? f.name : '';
    let activeSensor = f && f.sensors.length > 0 && f.sensors[0].is_activated ? 'true' : 'false';
    let signalSensor = f && f.sensors.length > 0 ? getSignalStatus(f.sensors[0].current_rssi) : 'no_signal';
    let batterySensor = f && f.sensors.length > 0 ? getBatteryStatus(f.sensors[0].current_battery) : { status: 'no_battery', value: -1 };

    let isOwner = userEmail === ownerEmail;
    let licenceStatus = true;

    let model = f && getModel(f);

    const handleMapIconClick = () => {
        setIsMapDialogVisible(true);
    };

    const handleCloseMapDialog = () => {
        setIsMapDialogVisible(false);
    };

    const handleOpenChartModal = (chartNumber) => {
        setIsChartModalVisible(true);
        setCurrentChart(chartNumber);
    };

    const handleCloseChartModal = () => {
        setIsChartModalVisible(false);
    };

    const menu = useRef(null);
    
    const items = [
        {
        label: t('field_card.sensor'),
        items: [
            {
                label: '',
                style: {display: 'none'},
                command: () => {
                    
                }
            },
            {
                label: t('field_card.change_sensor'),
                icon: 'pi pi-pencil',
                command: () => {
                    navigate('/linkSensor/'+fieldId);
                }
            }
        ]
        },
        {
        label: t('field_card.field'),
        items: [
            {
                label: t('field_card.delete_field'),
                icon: 'pi pi-trash',
                command: () => {
                    handleDeleteField();
                }
            }
        ]
        },
        {
        label: t('field_card.model'),
        items: [
            {
                label: haveModel === 'N' ? t('field_card.deactivate_nematode_model') : haveModel === 'S' ? t('field_card.deactivate_solarization_model') : t('field_card.activate_model'),
                icon: haveModel === 'N' || haveModel === 'S' ? 'pi pi-times' : 'pi pi-play',
                command: () => {
                    if (haveModel === 'N') {
                        handleStopNematode();
                    } else if (haveModel === 'S') {
                        handleStopSolarization();
                    } else {
                        handleStartModel();
                    }
                }
            }
        ]
        }
    ];

    const isMobile = window.innerWidth < 768;
    const isSmallMobile = window.innerWidth < 375;
    const isMediumMobile = window.innerWidth < 425;

    const openLicenseModal = (e) => {
        e.stopPropagation();
        setIsLicenseModalOpen(true);
    };
    const closeLicenseModal = () => setIsLicenseModalOpen(false);

    return (
        <><ConfirmDialog />

            <PostLoginWrapper isField={true} fieldId={fieldId} error={errorMessage}>
                <div className='flex flex-column align-items-center w-full'>
                    {isLoadingInit ?  (
                        <div className='flex justify-content-center'>
                            <div className="loading"></div>
                        </div>
                    ) : (
                        <>
                            {f && (
                                <Card 
                                className={`mt-5 mb-5`}
                                style={{ 
                                    padding: isSmallMobile ? '0.5rem' : '0.75rem',
                                    backgroundColor: 'white', 
                                    borderRadius: '10px',
                                    border: 'solid',
                                    borderRadius: '10px',
                                    borderWidth: '1px',
                                    borderColor: '#DEE2E6',
                                    boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.3)',
                                    width: isSmallMobile ? '95.6666%' : '91.6666%'
                                }}
                            >
                                    <div className='w-full '>
                                        <div className="flex justify-content-between align-items-center">
                                            <div className="flex align-items-center">
                                                <Badge
                                                    className="flex align-items-center text-sm bg-white border-round-3xl pl-0 text-black border-2 border-solid"
                                                    style={{ borderColor: '#DEE2E6', lineHeight: '1.5' }}
                                                    value={
                                                        <>
                                                            <span
                                                                style={{
                                                                    display: !haveSensor ? 'none' : 'block',
                                                                    width: '10px',
                                                                    height: '10px',
                                                                    borderRadius: '50%',
                                                                    marginRight: '5px',
                                                                    marginLeft: '5px',
                                                                    backgroundColor: haveSensor && activeSensor === 'true' ? 'var(--primary-color)' : '#FF0000'
                                                                }}
                                                            ></span>
                                                            <span style={{marginLeft: !haveSensor ? '5px' : '0px'}}>
                                                                {haveSensor ? `${sensorNumber}` : sensorNumber}
                                                            </span>
                                                        </>
                                                    }
                                                />
                                                {haveSensor ? (
                                                    <span 
                                                        onClick={(e) => openLicenseModal(e)}
                                                        style={{ cursor: 'pointer', color: isAvailableLicense ? isWarningLicense ? 'orange' : 'var(--primary-color)' : 'red', fontSize: '14px', marginLeft: '5px', userSelect: 'none' }}
                                                    >
                                                        {isAvailableLicense ? isWarningLicense ? 
                                                            <>
                                                                <u>{t('field_card.license')}</u> <FontAwesomeIcon icon={faWarning} /> 
                                                            </> : 
                                                            <>
                                                                <u>{t('field_card.license')}</u> <FontAwesomeIcon icon={faCheck} />
                                                            </> : 
                                                            <>
                                                                <u>{t('field_card.license')}</u> <FontAwesomeIcon icon={faTimes} />
                                                            </>
                                                        }
                                                    </span>
                                                ) : (
                                                    <span 
                                                        onClick={(e) => openLicenseModal(e)}
                                                        style={{ cursor: 'pointer', color: 'red', fontSize: '14px', marginLeft: '5px', userSelect: 'none', display: 'none' }}
                                                    >
                                                        <>
                                                            <u>{t('field_card.license')}</u> <FontAwesomeIcon icon={faTimes} />
                                                        </>
                                                    </span>
                                                )}
                                            </div>

                                            <div className="flex align-items-center">
                                                {haveSensor && activeSensor === 'true' ? (
                                                    <div className="flex align-items-center">
                                                        <span className="flex align-items-center text-black text-sm" style={{ marginLeft: '5px' }}>
                                                            {t(`field_card.${signalSensor}`)}
                                                            <Image src={getSignalIcon(signalSensor)} alt="signal icon" style={{ marginLeft: '2px', display: 'flex' }} pt={{ image: { width: '18px', height: '18px' } }} />
                                                        </span>
                                                        <span className="flex align-items-center text-black text-sm" style={{ marginLeft: '5px' }}>
                                                            {batterySensor.value === -1 ? 'no_battery' : batterySensor.value}%
                                                            <Image src={getBatteryIcon(batterySensor.status)} alt="battery icon" style={{ marginLeft: '2px', display: 'flex' }} pt={{ image: { width: '18px', height: '18px' } }} />
                                                        </span>
                                                    </div>
                                                ) : (
                                                    <div className="hidden"> {/*className="flex align-items-center"*/}
                                                        <span className="flex align-items-center text-black text-sm" style={{ marginLeft: '5px' }}>
                                                            {t(`field_card.${'no_signal'}`)}
                                                            <Image src={getSignalIcon('no_signal')} alt="signal icon" style={{ marginLeft: '2px', display: 'flex' }} pt={{ image: { width: '18px', height: '18px' } }} />
                                                        </span>
                                                        <span className="flex align-items-center text-black text-sm">
                                                            <Image src={getBatteryIcon('not_signal_battery')} alt="battery icon" style={{ marginLeft: '2px', display: 'flex' }} pt={{ image: { width: '18px', height: '18px' } }} />
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div style={{
                                            borderBottom: '1px solid #DEE2E6',
                                            margin: isSmallMobile ? '0 -0.5rem' : '0 -0.75rem',
                                            paddingTop: '0.75rem'
                                        }}></div>
                                        <div className="flex align-items-flex-start justify-content-between"  style={{paddingTop: '4px'}}>
                                                <h3 className="font-bold text-black mb-0 mt-0" style={{display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', overflow: 'hidden', wordBreak: 'break-all'}}>
                                                    {fieldName}
                                                </h3>
                                                <div>
                                                    <Menu
                                                        className="mt-2"
                                                        model={items}
                                                        popup
                                                        ref={menu}
                                                        id="popup_menu"
                                                        pt={{
                                                            action: { style: { padding: '10px' } },
                                                            label: { style: { color: 'black' } },
                                                            icon: { style: { color: 'black' } },
                                                            submenuheader: { style: { padding: '10px', color: 'black' } }
                                                        }}
                                                    />

                                                    <span 
                                                        className="font-medium text-primary text-sm underline cursor-pointer"
                                                        onClick={(event) => menu.current.toggle(event)}
                                                        aria-controls="popup_menu"
                                                        aria-haspopup
                                                        style={{display: isOwner ? 'flex' : 'none', marginTop: '4px'}}
                                                    >
                                                        {t('field.options')}
                                                        <FontAwesomeIcon className='pr-1' icon={faEllipsisV} style={{marginLeft: '5px', height: '18px'}} />
                                                    </span>
                                                    
                                                    {/*
                                                    <Button
                                                        className="ml-2 p-0"
                                                        icon="pi pi-ellipsis-v"
                                                        onClick={(event) => menu.current.toggle(event)}
                                                        aria-controls="popup_menu"
                                                        aria-haspopup
                                                        style={{ display: isOwner ? 'flex' : 'none', width: '11px', height: '22px', color: 'var(--primary-color)', backgroundColor: 'transparent', border: 'none', boxShadow: 'none' }}
                                                    />
                                                    */}
                                                </div>
                                            </div>
                                            
                                            <div className="flex align-items-center mt-1 mb-1">
                                                <span className="font-medium text-color-secondary text-sm">
                                                    {t(`field.crops.${crop}`)} -
                                                    {model[0] === 'Nematode active' && licenceStatus && (
                                                        <>
                                                            {model[1] === 0 && ` ${t('field_card.generation').charAt(0).toUpperCase() + t('field_card.generation').slice(1)} ${t('field_card.initial')} -`}
                                                            {model[1] !== 0 && ` ${model[1]}º ${t('field_card.generation')} -`}
                                                        </>
                                                    )}
                                                    <span className="font-medium text-primary text-sm underline cursor-pointer" style={{marginLeft: '5px'}} onClick={() => handleMapIconClick()}>
                                                        {t('field.map')}
                                                        <FontAwesomeIcon className='pr-1' icon={faMapMarkerAlt} style={{marginLeft: '5px'}}/>
                                                    </span>
                                                </span>
                                            </div>
                                        {isLoadingTrigger ? (
                                            <div className='flex justify-content-center'>
                                                <div className="loading"></div>
                                            </div>
                                        ) : (
                                            <>
                                                <div className='mt-3'>
                                                    {model[0] === 'Nematode active' && (
                                                        <div className="">
                                                            {haveSensor && f.sensors[0].license_info.nematode_ovicide && (
                                                                <Accordion multiple>
                                                                    <AccordionTab
                                                                        style={{
                                                                            border: '1px solid #DEE2E6',
                                                                            borderRadius: '5px'
                                                                        }}
                                                                        pt={{
                                                                            content: {
                                                                                style: {
                                                                                    padding: isSmallMobile ? '0.25rem' : '0.75rem'
                                                                                }
                                                                            },
                                                                            headeraction: {
                                                                                style: {
                                                                                    padding: isSmallMobile ? '0.50rem' : '0.75rem'
                                                                                }
                                                                            }
                                                                        }}
                                                                        header={
                                                                            <>
                                                                                <div>
                                                                                    <div className="flex justify-content-between align-items-center mb-2">
                                                                                        <span
                                                                                            className={`${model[2] > model[5] ? 'font-medium' : ''} text-color-secondary`}
                                                                                            style={model[3] === 'red' ? { animation: 'blinker 1s linear infinite' } : {}}
                                                                                        >
                                                                                            {t('field_card.egg')}
                                                                                        </span>
                                                                                        <span
                                                                                            className={`${model[2] > model[5] ? 'font-medium' : ''} uppercase text-sm text-color-secondary`}
                                                                                            style={model[3] === 'red' ? { animation: 'blinker 1s linear infinite' } : {}}
                                                                                        >
                                                                                            {model[3] === 'blue'
                                                                                                ? t('field_card.wait')
                                                                                                : (model[3] === 'orange'
                                                                                                    ? <>
                                                                                                        <FontAwesomeIcon icon={faExclamationTriangle} style={{ marginRight: '5px' }} />
                                                                                                        {t('field_card.prepare')}
                                                                                                      </>
                                                                                                    : (model[3] === 'red'
                                                                                                        ? <>
                                                                                                            <FontAwesomeIcon icon={faExclamationTriangle} style={{ marginRight: '5px' }} />
                                                                                                            {t('field_card.treat')}
                                                                                                          </>
                                                                                                        : t('field_card.wait')
                                                                                                      )
                                                                                                  )
                                                                                            }
                                                                                        </span>
                                                                                    </div>
                                                                                    <div style={{ padding: '3px', backgroundColor: "#DEE2E6", borderRadius: '50px' }}>
                                                                                        <ProgressBar
                                                                                            value={model[2] < 13 ? 13 : model[2]}
                                                                                            color={getColor(model[3])}
                                                                                            showValue={true}
                                                                                            displayValueTemplate={() => <span style={{ fontSize: '14px', fontWeight: '500' }}>{`${model[2]} %`}</span>}
                                                                                            style={{ borderRadius: '20px', height: '20px', backgroundColor: "#DEE2E6" }}
                                                                                            pt={{
                                                                                                value: {
                                                                                                    className: '',
                                                                                                    style: { backgroundColor: getColor(model[3]) }
                                                                                                }
                                                                                            }} />
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        }
                                                                    >
                                                                        <div className='w-full'>
                                                                            <div className='flex justify-content-center align-items-center mb-3'>
                                                                                <Calendar
                                                                                    id='init-date'
                                                                                    style={{ height: '30px' }}
                                                                                    inputStyle={isMediumMobile ? { fontSize: '13px', padding: '3px' } : {}}
                                                                                    value={initDate}
                                                                                    onChange={(e) => {
                                                                                        setInitDate(e.value);
                                                                                        fetchChartData(f.id, getModelId(f), e.value, lastDate);
                                                                                    }}
                                                                                    showIcon
                                                                                    dateFormat="dd/mm/yy"
                                                                                    readOnlyInput
                                                                                    className="mr-2" />
                                                                                <Calendar
                                                                                    id='last-date'
                                                                                    style={{ height: '30px' }}
                                                                                    inputStyle={isMediumMobile ? { fontSize: '13px', padding: '3px' } : {}}
                                                                                    value={lastDate}
                                                                                    onChange={(e) => {
                                                                                        setLastDate(e.value);
                                                                                        fetchChartData(f.id, getModelId(f), initDate, e.value);
                                                                                    }}
                                                                                    showIcon
                                                                                    dateFormat="dd/mm/yy"
                                                                                    readOnlyInput
                                                                                    pt={{
                                                                                        button: { className: 'bg-primary' }
                                                                                    }} />
                                                                                <FontAwesomeIcon
                                                                                    icon={faDownload}
                                                                                    className='bg-primary'
                                                                                    style={{
                                                                                        color: 'white',
                                                                                        width: '14px',
                                                                                        height: '14px',
                                                                                        marginLeft: '10px',
                                                                                        borderRadius: '7px',
                                                                                        padding: '10px',
                                                                                        paddingTop: '8px',
                                                                                        paddingBottom: '8px',
                                                                                        cursor: 'pointer'
                                                                                    }}
                                                                                    onClick={() => downloadData('ovicide')} />
                                                                                <div className="hidden lg:block">
                                                                                    <FontAwesomeIcon
                                                                                        icon={faExpand}
                                                                                        className='bg-primary'
                                                                                        style={{
                                                                                            marginLeft: '10px',
                                                                                            color: 'white',
                                                                                            borderRadius: '7px',
                                                                                            padding: '10px',
                                                                                            paddingTop: '7px',
                                                                                            paddingBottom: '7px',
                                                                                            cursor: 'pointer'
                                                                                        }}
                                                                                        onClick={() => handleOpenChartModal(1)}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            {isLoading ? (
                                                                                <div id="chart-container" style={{ position: 'relative' }} className='w-full'>
                                                                                    <div className='flex justify-content-center' style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                                                                        <div className="loading mt-0"></div>
                                                                                    </div>
                                                                                    <Chart
                                                                                        className='w-full'
                                                                                        style={{ height: '350px' }}
                                                                                        type="line"
                                                                                    />
                                                                                </div>
                                                                            ) : (
                                                                                <div id="chart-container" className='w-full'>
                                                                                    <Chart
                                                                                        className='w-full'
                                                                                        style={{ height: '350px' }}
                                                                                        type="line"
                                                                                        data={chartData1}
                                                                                        options={multiAxisOptions}
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </AccordionTab>
                                                                </Accordion>
                                                            )}

                                                            {haveSensor && f.sensors[0].license_info.nematode_larvicide && (
                                                                <Accordion multiple style={{ marginTop: '10px' }}>
                                                                    <AccordionTab
                                                                        style={{
                                                                            border: '1px solid #DEE2E6',
                                                                            borderRadius: '5px'
                                                                        }}
                                                                        pt={{
                                                                            content: {
                                                                                style: {
                                                                                    padding: isSmallMobile ? '0.25rem' : '0.75rem'
                                                                                }
                                                                            },
                                                                            headeraction: {
                                                                                style: {
                                                                                    padding: isSmallMobile ? '0.50rem' : '0.75rem'
                                                                                }
                                                                            }
                                                                        }}
                                                                        header={
                                                                            <div className="w-full">
                                                                                <div className="flex justify-content-between align-items-center mb-2">
                                                                                    <span className={`${model[5] > model[2] ? 'font-medium' : ''} text-color-secondary`}>
                                                                                        {t('field_card.larvicide')}
                                                                                    </span>
                                                                                    <span
                                                                                        className={`${model[5] > model[2] ? 'font-medium' : ''} uppercase text-sm text-color-secondary`}
                                                                                        style={model[6] === 'red' ? { animation: 'blinker 1s linear infinite' } : {}}
                                                                                    >
                                                                                        {model[6] === 'blue'
                                                                                            ? t('field_card.wait')
                                                                                            : (model[6] === 'orange'
                                                                                                ? <>
                                                                                                    <FontAwesomeIcon icon={faExclamationTriangle} style={{ marginRight: '5px' }} />
                                                                                                    {t('field_card.prepare')}
                                                                                                  </>
                                                                                                : (model[6] === 'red'
                                                                                                    ? <>
                                                                                                        <FontAwesomeIcon icon={faExclamationTriangle} style={{ marginRight: '5px' }} />
                                                                                                        {t('field_card.treat')}
                                                                                                      </>
                                                                                                    : t('field_card.wait')
                                                                                                  )
                                                                                                )
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                                <div style={{ padding: '3px', backgroundColor: "#DEE2E6", borderRadius: '50px' }}>
                                                                                    <ProgressBar
                                                                                        value={model[5] < 13 ? 13 : model[5]}
                                                                                        color={getColor(model[6])}
                                                                                        showValue={true}
                                                                                        displayValueTemplate={() => <span style={{ fontSize: '14px', fontWeight: '500' }}>{`${model[5]} %`}</span>}
                                                                                        style={{ borderRadius: '20px', height: '20px', backgroundColor: "#DEE2E6" }}
                                                                                        pt={{
                                                                                            value: {
                                                                                                className: '',
                                                                                                style: { backgroundColor: getColor(model[6]) }
                                                                                            }
                                                                                        }} />
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    >
                                                                        <div className='w-full'>
                                                                            <div className='flex justify-content-center align-items-center mb-3'>
                                                                                <Calendar
                                                                                    id='init-date'
                                                                                    style={{ height: '30px' }}
                                                                                    inputStyle={isMediumMobile ? { fontSize: '13px', padding: '3px' } : {}}
                                                                                    value={initDate}
                                                                                    onChange={(e) => {
                                                                                        setInitDate(e.value);
                                                                                        fetchChartData(f.id, getModelId(f), e.value, lastDate);
                                                                                    }}
                                                                                    showIcon
                                                                                    dateFormat="dd/mm/yy"
                                                                                    readOnlyInput
                                                                                    className="mr-2" />
                                                                                <Calendar
                                                                                    id='last-date'
                                                                                    style={{ height: '30px' }}
                                                                                    inputStyle={isMediumMobile ? { fontSize: '13px', padding: '3px' } : {}}
                                                                                    value={lastDate}
                                                                                    onChange={(e) => {
                                                                                        setLastDate(e.value);
                                                                                        fetchChartData(f.id, getModelId(f), initDate, e.value);
                                                                                    }}
                                                                                    showIcon
                                                                                    dateFormat="dd/mm/yy"
                                                                                    readOnlyInput
                                                                                    pt={{
                                                                                        button: { className: 'bg-primary' }
                                                                                    }} />
                                                                                <FontAwesomeIcon
                                                                                    icon={faDownload}
                                                                                    className='bg-primary'
                                                                                    style={{
                                                                                        color: 'white',
                                                                                        width: '14px',
                                                                                        height: '14px',
                                                                                        marginLeft: '10px',
                                                                                        borderRadius: '7px',
                                                                                        padding: '10px',
                                                                                        paddingTop: '8px',
                                                                                        paddingBottom: '8px',
                                                                                        cursor: 'pointer'
                                                                                    }}
                                                                                    onClick={() => downloadData('larvicide')} />
                                                                                <div className="hidden lg:block">
                                                                                    <FontAwesomeIcon
                                                                                        icon={faExpand}
                                                                                        className='bg-primary'
                                                                                        style={{
                                                                                            marginLeft: '10px',
                                                                                            color: 'white',
                                                                                            borderRadius: '7px',
                                                                                            padding: '10px',
                                                                                            paddingTop: '7px',
                                                                                            paddingBottom: '7px',
                                                                                            cursor: 'pointer'
                                                                                        }}
                                                                                        onClick={() => handleOpenChartModal(2)}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            {isLoading ? (
                                                                                <div id="chart-container" style={{ position: 'relative' }} className='w-full'>
                                                                                    <div className='flex justify-content-center' style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                                                                        <div className="loading mt-0"></div>
                                                                                    </div>
                                                                                    <Chart
                                                                                        className='w-full'
                                                                                        style={{ height: '350px' }}
                                                                                        type="line"
                                                                                    />
                                                                                </div>
                                                                            ) : (
                                                                                <div id="chart-container" className='w-full'>
                                                                                    <Chart
                                                                                        className='w-full'
                                                                                        style={{ height: '350px' }}
                                                                                        type="line"
                                                                                        data={chartData2}
                                                                                        options={multiAxisOptions}
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </AccordionTab>
                                                                </Accordion>
                                                            )}
                                                            <Timeline
                                                                value={events}
                                                                className='mt-3'

                                                                marker={(item) => (
                                                                    <span className="flex w-1rem h-1rem align-items-center justify-content-center text-white border-circle z-1 shadow-1" 
                                                                        style={{ 

                                                                            backgroundColor: item.status.includes('alert') ? '#ED5F55' : 
                                                                                           item.status.includes('warning') ? '#F6894E' :
                                                                                           'var(--primary-color)'
                                                                        }}>

                                                                    </span>
                                                                )}
                                                                content={(item) => (
                                                                    <div className="flex justify-content-between align-items-center w-full">
                                                                        <div className="flex flex-column align-items-start">
                                                                        <small className="text-color-secondary">
                                                                                {new Date(item.date).toLocaleDateString('es-ES', {
                                                                                    day: '2-digit',
                                                                                    month: '2-digit',
                                                                                    year: 'numeric'
                                                                                })}
                                                                            </small>
                                                                            <small className="text-color-secondary font-bold">
                                                                                {item.generation !== undefined ? (
                                                                                    item.generation === 0
                                                                                        ? `${t('field_card.generation').charAt(0).toUpperCase() + t('field_card.generation').slice(1)} ${t('field_card.initial')}: ${t(`notifications.${item.status}`)}`
                                                                                        : `${item.generation}º ${t('field_card.generation')}: ${t(`notifications.${item.status}`)}`
                                                                                ) : t(`notifications.${item.status}`)}
                                                                            </small>
                                                                        </div>
                                                                    </div>
                                                                )}>
                                                            </Timeline>
                                                        </div>
                                                    )}

                                                    {model[0] === 'Solarization active' && (
                                                        <div className="">
                                                            {haveSensor && f.sensors[0].license_info.solarization ? (
                                                                <div>
                                                                    <Accordion multiple>
                                                                        <AccordionTab
                                                                            style={{
                                                                                border: '1px solid #DEE2E6',
                                                                                borderRadius: '5px'
                                                                            }}
                                                                            pt={{
                                                                                content: {
                                                                                    style: {
                                                                                        padding: isSmallMobile ? '0.25rem' : '0.75rem'
                                                                                    }
                                                                                },
                                                                                headeraction: {
                                                                                    style: {
                                                                                        padding: isSmallMobile ? '0.50rem' : '0.75rem'
                                                                                    }
                                                                                }
                                                                            }}
                                                                            header={
                                                                                <div className="w-full">
                                                                                    <div className="flex justify-content-between align-items-center mb-2">
                                                                                        <span className="font-medium text-color-secondary">{t('field_card.degrees')}</span>
                                                                                        <span className="font-medium uppercase text-color-secondary">
                                                                                            {model[1] < 50 ? t('field_card.null') :
                                                                                                model[1] < 500 ? t('field_card.low') :
                                                                                                    model[1] < 1500 ? t('field_card.moderate') :
                                                                                                        model[1] < 3000 ? t('field_card.good') :
                                                                                                            model[1] < 6000 ? t('field_card.excellent') :
                                                                                                                t('field_card.super')}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div style={{ padding: '3px', backgroundColor: "#DEE2E6", borderRadius: '50px' }}>
                                                                                        <ProgressBar
                                                                                            value={model[1] < 100 ? 13 :
                                                                                                model[1] < 1000 ? 16 :
                                                                                                    model[1] < 1333 ? 19 :
                                                                                                        model[1] < 1500 ? 21 :
                                                                                                            model[1] < 1777 ? 23 :
                                                                                                                model[1] < 2000 ? 25 :
                                                                                                                    model[1] > 7000 ? 100 :
                                                                                                                        (model[1] / 7000) * 100}
                                                                                            color={getColor(model[3])}
                                                                                            showValue={true}
                                                                                            displayValueTemplate={() => <span style={{ fontSize: '14px', fontWeight: '500', backgroundColor: getColorCard(model) }}>{`${model[1]} ºC`}</span>}
                                                                                            style={{ borderRadius: '20px', height: '20px', backgroundColor: "#DEE2E6" }}
                                                                                            pt={{
                                                                                                value: {
                                                                                                    className: '',
                                                                                                    style: { backgroundColor: getColorCard(model) }
                                                                                                }
                                                                                            }} />
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        >
                                                                            <div className='w-full'>
                                                                                <div className='flex justify-content-center align-items-center mb-3'>
                                                                                    <Calendar
                                                                                        id='init-date'
                                                                                        style={{ height: '30px' }}
                                                                                        inputStyle={isMediumMobile ? {
                                                                                            fontSize: '13px',
                                                                                            padding: '3px'
                                                                                        } : {}}
                                                                                        value={initDate}
                                                                                        onChange={(e) => {
                                                                                            setInitDate(e.value);
                                                                                            fetchChartData(f.id, getModelId(f), e.value, lastDate);
                                                                                        }}
                                                                                        showIcon
                                                                                        dateFormat="dd/mm/yy"
                                                                                        readOnlyInput
                                                                                        className="mr-2" />
                                                                                    <Calendar
                                                                                        id='last-date'
                                                                                        style={{ height: '30px' }}
                                                                                        inputStyle={isMediumMobile ? {
                                                                                            fontSize: '13px',
                                                                                            padding: '3px'
                                                                                        } : {}}
                                                                                        value={lastDate}
                                                                                        onChange={(e) => {
                                                                                            setLastDate(e.value);
                                                                                            fetchChartData(f.id, getModelId(f), initDate, e.value);
                                                                                        }}
                                                                                        showIcon
                                                                                        dateFormat="dd/mm/yy"
                                                                                        readOnlyInput
                                                                                        pt={{
                                                                                            button: { className: 'bg-primary' }
                                                                                        }} />
                                                                                    <FontAwesomeIcon
                                                                                        icon={faDownload}
                                                                                        className='bg-primary'
                                                                                        style={{
                                                                                            color: 'white',
                                                                                            width: '14px',
                                                                                            height: '14px',
                                                                                            marginLeft: '10px',
                                                                                            borderRadius: '7px',
                                                                                            padding: '10px',
                                                                                            paddingTop: '8px',
                                                                                            paddingBottom: '8px',
                                                                                            cursor: 'pointer'
                                                                                        }}
                                                                                        onClick={() => downloadData('solarization')} />
                                                                                    <div className="hidden lg:block">
                                                                                        <FontAwesomeIcon
                                                                                            icon={faExpand}
                                                                                            className='bg-primary'
                                                                                            style={{
                                                                                                marginLeft: '10px',
                                                                                                color: 'white',
                                                                                                borderRadius: '7px',
                                                                                                padding: '10px',
                                                                                                paddingTop: '7px',
                                                                                                paddingBottom: '7px',
                                                                                                cursor: 'pointer'
                                                                                            }}
                                                                                            onClick={() => handleOpenChartModal(1)}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                {isLoading ? (
                                                                                    <div id="chart-container" style={{ position: 'relative' }} className='w-full'>
                                                                                        <div className='flex justify-content-center' style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                                                                            <div className="loading mt-0"></div>
                                                                                        </div>
                                                                                        <Chart
                                                                                            className='w-full'
                                                                                            style={{ height: '350px' }}
                                                                                            type="line"
                                                                                        />
                                                                                    </div>
                                                                                ) : (
                                                                                    <div id="chart-container" className='w-full'>
                                                                                        <Chart
                                                                                            className='w-full'
                                                                                            style={{ height: '350px' }}
                                                                                            type="line"
                                                                                            data={chartData1}
                                                                                            options={multiAxisOptions}
                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </AccordionTab>
                                                                    </Accordion>
                                                                    <Timeline
                                                                        value={events}
                                                                        className='mt-3'

                                                                        marker={(item) => (
                                                                            <span className="flex w-1rem h-1rem align-items-center justify-content-center text-white border-circle z-1 shadow-1" 
                                                                                style={{ 

                                                                                    backgroundColor: item.status.includes('alert') ? '#ED5F55' : 
                                                                                                item.status.includes('warning') ? '#F6894E' :
                                                                                                'var(--primary-color)'
                                                                                }}>

                                                                            </span>
                                                                        )}
                                                                        content={(item) => (
                                                                            <div className="flex justify-content-between align-items-center w-full">
                                                                                <div className="flex flex-column align-items-start">
                                                                                <small className="text-color-secondary">
                                                                                        {new Date(item.date).toLocaleDateString('es-ES', {
                                                                                            day: '2-digit',
                                                                                            month: '2-digit',
                                                                                            year: 'numeric'
                                                                                        })}
                                                                                    </small>
                                                                                    <small className="text-color-secondary font-bold">
                                                                                        {t(`notifications.${item.status}`)}
                                                                                    </small>
                                                                                </div>
                                                                            </div>
                                                                        )}>
                                                                    </Timeline>
                                                                </div>
                                                            )  : (
                                                                isOwner ? (
                                                                    <div className="relative">
                                                                        {haveSensor ? (
                                                                            <>
                                                                                {f.sensors[0].license_info.solarization ? (
                                                                                    <span className="text-black">{t('field_card.link_license_text')}</span>
                                                                                ) : (
                                                                                    <span className="text-black">{t('field_card.link_license_text_solarization')}</span>
                                                                                )}
                                                                                <Link to={`/addLicense`} state={{ sensorNumber, fieldId }} className="text-primary">
                                                                                    {t('field_card.link_license_click')}
                                                                                </Link>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <span className="text-black">{t('field_card.link_sensor_text')}</span>
                                                                                <Link to={`/linkSensor/${fieldId}`} state={{ fieldId }} className="text-primary">
                                                                                    {t('field_card.link_sensor_click')}
                                                                                </Link>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                ) : (
                                                                    <div className="relative">
                                                                        <span className="text-black">
                                                                            {haveSensor ? 
                                                                                f.sensors[0].license_info.solarization ? 
                                                                                    t('field_card.no_license_text') : 
                                                                                    t('field_card.no_license_text_solarization') : 
                                                                                t('field_card.no_sensor_linked')}
                                                                        </span>
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    )}

                                                    {model[0] === 'No model' && (
                                                        <div className="">
                                                            {haveSensor && f.sensors[0].license_info.temperature ? (
                                                                <Accordion multiple>
                                                                    <AccordionTab
                                                                        style={{
                                                                            border: '1px solid #DEE2E6',
                                                                            borderRadius: '5px'
                                                                        }}
                                                                        header={
                                                                            <div className="w-full">
                                                                                <div className="flex justify-content-between align-items-center">
                                                                                    <span className="font-medium text-color-secondary">{t('field.temperature')}</span>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        pt={{
                                                                            content: {
                                                                                style: {
                                                                                    padding: isSmallMobile ? '0.25rem' : '0.75rem'
                                                                                }
                                                                            },
                                                                            headeraction: {
                                                                                style: {
                                                                                    padding: isSmallMobile ? '0.50rem' : '0.75rem'
                                                                                }
                                                                            }
                                                                        }}
                                                                    >
                                                                        <div className='w-full'>
                                                                            <div className='flex justify-content-center align-items-center mb-3'>
                                                                                <Calendar
                                                                                    id='init-date'
                                                                                    style={{ height: '30px' }}
                                                                                    inputStyle={isMediumMobile ? {
                                                                                        fontSize: '13px',
                                                                                        padding: '3px'
                                                                                    } : {}}
                                                                                    value={initDate}
                                                                                    onChange={(e) => {
                                                                                        setInitDate(e.value);
                                                                                        fetchChartData(f.id, getModelId(f), e.value, lastDate);
                                                                                    }}
                                                                                    showIcon
                                                                                    dateFormat="dd/mm/yy"
                                                                                    readOnlyInput
                                                                                    className="mr-2" />
                                                                                <Calendar
                                                                                    id='last-date'
                                                                                    style={{ height: '30px' }}
                                                                                    inputStyle={isMediumMobile ? {
                                                                                        fontSize: '13px',
                                                                                        padding: '3px'
                                                                                    } : {}}
                                                                                    value={lastDate}
                                                                                    onChange={(e) => {
                                                                                        setLastDate(e.value);
                                                                                        fetchChartData(f.id, getModelId(f), initDate, e.value);
                                                                                    }}
                                                                                    showIcon
                                                                                    dateFormat="dd/mm/yy"
                                                                                    readOnlyInput
                                                                                    pt={{
                                                                                        button: { className: 'bg-primary' }
                                                                                    }} />
                                                                                <FontAwesomeIcon
                                                                                    icon={faDownload}
                                                                                    className='bg-primary'
                                                                                    style={{
                                                                                        color: 'white',
                                                                                        width: '14px',
                                                                                        height: '14px',
                                                                                        marginLeft: '10px',
                                                                                        borderRadius: '7px',
                                                                                        padding: '10px',
                                                                                        paddingTop: '8px',
                                                                                        paddingBottom: '8px',
                                                                                        cursor: 'pointer'
                                                                                    }}
                                                                                    onClick={() => downloadData('temperature')} />
                                                                                <div className="hidden lg:block">
                                                                                    <FontAwesomeIcon
                                                                                        icon={faExpand}
                                                                                        className='bg-primary'
                                                                                        style={{
                                                                                            marginLeft: '10px',
                                                                                            color: 'white',
                                                                                            borderRadius: '7px',
                                                                                            padding: '10px',
                                                                                            paddingTop: '7px',
                                                                                            paddingBottom: '7px',
                                                                                            cursor: 'pointer'
                                                                                        }}
                                                                                        onClick={() => handleOpenChartModal(1)}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            {isLoading ? (
                                                                                <div id="chart-container" style={{ position: 'relative' }} className='w-full'>
                                                                                    <div className='flex justify-content-center' style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                                                                        <div className="loading mt-0"></div>
                                                                                    </div>
                                                                                    <Chart
                                                                                        className='w-full'
                                                                                        style={{ height: '350px' }}
                                                                                        type="line"
                                                                                    />
                                                                                </div>
                                                                            ) : (
                                                                                <div id="chart-container" className='w-full'>
                                                                                    <Chart
                                                                                        className='w-full'
                                                                                        style={{ height: '350px' }}
                                                                                        type="line"
                                                                                        data={chartData1}
                                                                                        options={multiAxisOptions}
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </AccordionTab>
                                                                </Accordion>
                                                            )  : (
                                                                isOwner ? (
                                                                    <div className="relative">
                                                                        {haveSensor ? (
                                                                            <>
                                                                                {f.sensors[0].license_info.temperature ? (
                                                                                    <span className="text-black">{t('field_card.link_license_text')}</span>
                                                                                ) : (
                                                                                    <span className="text-black">{t('field_card.link_license_text_temperature')}</span>
                                                                                )}
                                                                                <Link to={`/addLicense`} state={{ sensorNumber, fieldId }} className="text-primary">
                                                                                    {t('field_card.link_license_click')}
                                                                                </Link>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <span className="text-black">{t('field_card.link_sensor_text')}</span>
                                                                                <Link to={`/linkSensor/${fieldId}`} state={{ fieldId }} className="text-primary">
                                                                                    {t('field_card.link_sensor_click')}
                                                                                </Link>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                ) : (
                                                                    <div className="relative">
                                                                        <span className="text-black">
                                                                            {haveSensor ? 
                                                                                f.sensors[0].license_info.temperature ? 
                                                                                    t('field_card.no_license_text') : 
                                                                                    t('field_card.no_license_text_temperature') : 
                                                                                t('field_card.no_sensor_linked')}
                                                                        </span>
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </Card>
                            )}
                        </>
                    )}

                    {/* Interpolate modal 
                    <ModalInterpolate
                        isOpen={isInterpolateModalOpen}
                        onClose={closeInterpolateModal}
                        sensorId={f && f.sensors[0] ? f.sensors[0].id : null} />
                    */}

                    <ConfirmDialog
                        className="w-11 desktop-max-width border-round-2xl"
                        visible={showStartModelDialog}
                        message={isFirstLoad ? t('field.start_model_init_message') : t('field.start_model_message')}
                        header={ t('field_card.activate_model')}
                        acceptLabel={t('field.nematode_model_button')}
                        rejectLabel={t('field.solarization_model_button')}
                        acceptClassName={
                            f && f.sensors[0]?.license_info?.nematode_ovicide
                                ? "bg-[#449E48] w-6"
                                : "hidden"
                        }
                        rejectClassName={
                            f && f.sensors[0]?.license_info?.solarization
                                ? "bg-[#449E48] w-6"
                                : "hidden"
                        }
                        defaultFocus="none"
                        focusOnShow={false}
                        onHide={() => {
                            if (showStartModelDialog) {
                                setShowStartModelDialog(false);
                                setIsFirstLoad(false);
                            }
                        }}
                        accept={async () => {
                            setHaveModel('N');
                            await handleStartNematode();
                            setShowStartModelDialog(false);
                        }}
                        reject={async () => {
                            setHaveModel('S');
                            await handleStartSolarization();
                            setShowStartModelDialog(false);
                        }}
                        pt={{
                            header: { className: 'border-round-top-2xl' },
                            footer: { className: 'border-round-bottom-2xl' }
                        }}
                    />

                    <ConfirmDialog
                        className="w-11 desktop-max-width border-round-2xl"
                        visible={showStopNematodeDialog}
                        message={t('field.stop_nematode_message')}
                        header={t('field_card.deactivate_model')}
                        icon="pi pi-exclamation-triangle"
                        acceptLabel={t('field.confirmDialog')}
                        rejectLabel={t('field.rejectDialog')}
                        acceptClassName="bg-[#449E48] w-3"
                        rejectClassName="bg-[#449E48] w-3"
                        defaultFocus="none"
                        focusOnShow={false}
                        onHide={() => setShowStopNematodeDialog(false)}
                        accept={async () => {
                            setHaveModel('No model');
                            await putNematode(f.id, f.active_prediction_model.id);
                            setRefreshTrigger(prev => !prev);
                            //setIsLoadingTrigger(true);
                            setIsLoadingInit(true);
                            setShowStopNematodeDialog(false);
                        }}
                        pt={{
                            header: { className: 'border-round-top-2xl' },
                            footer: {className: 'border-round-bottom-2xl'}
                        }}
                    />

                    <ConfirmDialog
                        className="w-11 desktop-max-width border-round-2xl"
                        visible={showStopSolarizationDialog}
                        message={t('field.stop_solarization_message')}
                        header={t('field_card.deactivate_model')}
                        icon="pi pi-exclamation-triangle"
                        acceptLabel={t('field.confirmDialog')}
                        rejectLabel={t('field.rejectDialog')}
                        acceptClassName="bg-[#449E48] w-3"
                        rejectClassName="bg-[#449E48] w-3"
                        defaultFocus="none"
                        focusOnShow={false}
                        onHide={() => setShowStopSolarizationDialog(false)}
                        accept={async () => {
                            setHaveModel('No model');
                            await putSolarization(f.id, f.active_solarization.id);
                            setRefreshTrigger(prev => !prev);
                            //setIsLoadingTrigger(true);
                            setIsLoadingInit(true);
                            setShowStopSolarizationDialog(false);
                        }}
                        pt={{
                            header: { className: 'border-round-top-2xl' },
                            footer: {className: 'border-round-bottom-2xl'}
                        }}
                    />

                    <ConfirmDialog
                        className="w-11 desktop-max-width border-round-2xl"
                        visible={showDeleteFieldDialog}
                        message={t('field.delete_field_message')}
                        header={t('field.adverse')}
                        acceptLabel={t('field.confirmDialog')}
                        acceptClassName="bg-[#449E48]"
                        rejectClassName="hidden"
                        defaultFocus="none"
                        focusOnShow={false}
                        onHide={() => setShowDeleteFieldDialog(false)}
                        accept={async () => {
                            await deleteField(f.id);
                            setShowDeleteFieldDialog(false);
                            navigate('/fields');
                        }}
                        pt={{
                            header: { className: 'border-round-top-2xl' },
                            footer: {className: 'border-round-bottom-2xl'}
                        }}
                    />

                    <ConfirmDialog
                        className="w-11 desktop-max-width border-round-2xl"
                        visible={showDownloadDataDialog}
                        message={t('field.download_data_message')}
                        header={t('field.download_data')}
                        acceptLabel={t('field.model')}
                        rejectLabel={t('field.temperature')}
                        acceptClassName={model && model[0] === 'No model' ? 'hidden' : 'bg-[#449E48] w-6'}
                        rejectClassName='bg-[#449E48] w-6'
                        defaultFocus="none"
                        focusOnShow={false}
                        onHide={() => setShowDownloadDataDialog(false)}
                        accept={async () => {
                            await downloadModelData(model, downloadType);
                            setShowDownloadDataDialog(false);
                        }}
                        reject={async () => {
                            await downloadTemperatureData(model, downloadType);
                            setShowDownloadDataDialog(false);
                        }}
                        pt={{
                            header: { className: 'border-round-top-2xl' },
                            footer: {className: 'border-round-bottom-2xl'}
                        }}
                    />

                    <Dialog 
                        header={<span className='text-color-secondary'>{t('field.location')}</span>}
                        footer={
                            <div className='flex flex-column align-items-center'>
                            <div className="flex flex-column" style={{paddingTop: '1.5rem'}} onClick={() => {
                            }}>
                                    <span className='text-color-secondary'>
                                        {t('field.latitude')}: {lat.toFixed(3)} | {t('field.longitude')}: {lon.toFixed(3)}
                                    </span>
                                    <span className='text-color-secondary pt-1'>
                                        {t('field.address')}: {address}
                                    </span>
                            </div>
                            <span 
                                className='underline text-color-secondary pt-1 cursor-pointer' 
                                onClick={() => handleOpenMaps()}>
                                    {t('field.open_maps')}
                            </span>
                            </div>
                        }
                        visible={isMapDialogVisible} 
                        onHide={() => setIsMapDialogVisible(false)}
                        className='w-11 p-0 border-round-2xl'
                        focusOnShow={false}
                        pt={{
                            header: { className: 'border-round-top-2xl' },
                            content: {className: 'p-0'},
                            footer: {className: 'border-round-bottom-2xl text-center'}
                        }}
                    >
                        <div className='flex align-items-center justify-content-center w-full map-div'>
                            <Map latitude={lat} longitude={lon} onMapLoad={handleMapLoad} clickable={false} />
                        </div>
                    </Dialog>

                    <Dialog
                        visible={isChartModalVisible}
                        onHide={handleCloseChartModal}
                        className='w-11 p-0 border-round-2xl'
                        focusOnShow={false}
                        pt={{
                            header: { className: 'border-round-top-2xl' },
                            content: { className: 'border-round-bottom-2xl' }
                        }}
                    >
                        <div className='flex flex-column align-items-center w-full'>
                            <div className='flex justify-content-center align-items-center mb-3'>
                                <Calendar
                                    id='modal-init-date'
                                    style={{ height: '30px' }}
                                    inputStyle={isMediumMobile ? {
                                        fontSize: '13px',
                                        padding: '3px'
                                    } : {}}
                                    value={initDate}
                                    onChange={(e) => {
                                        setInitDate(e.value);
                                        fetchChartData(f.id, getModelId(f), e.value, lastDate);
                                    }}
                                    showIcon
                                    dateFormat="dd/mm/yy"
                                    readOnlyInput
                                    className="mr-2" />
                                <Calendar
                                    id='modal-last-date'
                                    style={{ height: '30px' }}
                                    inputStyle={isMediumMobile ? {
                                        fontSize: '13px',
                                        padding: '3px'
                                    } : {}}
                                    value={lastDate}
                                    onChange={(e) => {
                                        setLastDate(e.value);
                                        fetchChartData(f.id, getModelId(f), initDate, e.value);
                                    }}
                                    showIcon
                                    dateFormat="dd/mm/yy"
                                    readOnlyInput />
                            </div>
                            <Chart
                                className='w-full'
                                style={{ height: '500px' }}
                                type="line"
                                data={currentChart === 1 ? chartData1 : chartData2}
                                options={multiAxisOptions}
                            />
                        </div>
                    </Dialog>

                    <ModalLicenseExpiration 
                        isOpen={isLicenseModalOpen}
                        onClose={closeLicenseModal}
                        fieldId={fieldId}
                        isOwner={isOwner}
                        licenseInfo={license_info}
                    />
                </div>
            </PostLoginWrapper>
        </>
    );
};