import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const ModalMoreInfo = ({ isOpen, onClose, info_text }) => {
    const { t } = useTranslation();

    return (
        <Dialog 
            header={t('field_card.info')} 
            visible={isOpen}
            focusOnShow={false}
            className="w-11 desktop-max-width"
            onHide={onClose} 
            onClick={e => e.stopPropagation()}
            pt={{
                header: { className: 'border-round-top-2xl' },
                content: { className: 'border-round-bottom-2xl' }
            }}
            modal>
            <div>
                <p>
                    {info_text}
                </p>
            </div>
        </Dialog>
    );
}; 