import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card } from 'primereact/card';
import { ProgressBar } from 'primereact/progressbar';
import { Badge } from 'primereact/badge';
import { Image } from 'primereact/image';
import { Chart } from 'primereact/chart';
import { Link } from 'react-router-dom';
import { getGuests } from '../actions/fieldsActions';
import { getColor, getColorFooter, getColorCard, getColorCardFooter } from '../utils/fieldUtils';
import { availableLicense, warningLicense, formatDateLicence, expirationDays } from '../utils/functions';
import { getTemperatureData } from '../actions/temperatureActions';
import well_signal from '../assets/well_signal_black.svg';
import half_signal from '../assets/half_signal_black.svg';
import low_signal from '../assets/low_signal_black.svg';
import not_signal from '../assets/not_signal_black.svg';
import not_battery from '../assets/not_battery_black.svg';
import low_battery from '../assets/low_battery_black.svg';
import half_battery from '../assets/half_battery_black.svg';
import well_battery from '../assets/well_battery_black.svg';
import not_signal_battery from '../assets/not_signal_battery_black.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faShareNodes, faUserPlus, faUserXmark, faCheck, faTimes, faWarning, faMapMarkerAlt, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { ModalShared } from './ModalShared';
import { ModalRejectShared } from './ModalRejectShared';
import { ModalDeleteField } from './ModalDeleteField';
import { ModalLicenseExpiration } from './ModalLicenseExpiration';
import { ModalMoreInfo } from './ModalMoreInfo';

export const FieldCard = ({ fieldId, fieldName, sensorNumber, activeSensor, signalSensor, batterySensor, cropId, model, ownerEmail, userEmailLogin, userId, license_info, isFields, fieldsPerRow, setTrigger }) => {
    const navigate = useNavigate();
    const [temperatureData, setTemperatureData] = useState([]);
    const { t } = useTranslation();
    const [isSharedUsersModalOpen, setIsSharedUsersModalOpen] = useState(false);
    const [isRejectSharedModalOpen, setIsRejectSharedModalOpen] = useState(false);
    const [sharedUsers, setSharedUsers] = useState([]);
    const [isDeleteFieldModalOpen, setIsDeleteFieldModalOpen] = useState(false);
    const [isLicenseModalOpen, setIsLicenseModalOpen] = useState(false);
    const [isMoreInfoModalOpen, setIsMoreInfoModalOpen] = useState(false);
    const [info_text, setInfoText] = useState('');
    let haveSensor = true;
    let isAvailableLicense = null;
    let isWarningLicense = null;
    let daysExpiredLicense = null;

    if (sensorNumber === 'no_sensor'){
        sensorNumber = t('field_card.no_sensor');
        haveSensor = false;
    }

    if (license_info !== null) {
        isAvailableLicense = availableLicense(license_info.expiration_license_date);
        isWarningLicense = warningLicense(license_info.expiration_license_date);
        daysExpiredLicense = expirationDays(license_info.expiration_license_date);
    }

    // Variable para determinar si algún modal está activo
    const isAnyModalOpen = isLicenseModalOpen || isMoreInfoModalOpen || isSharedUsersModalOpen || isRejectSharedModalOpen || isDeleteFieldModalOpen;

    const openLicenseModal = (e) => {
        e.stopPropagation();
        setIsLicenseModalOpen(true);
    };

    const openMoreInfoModal = (e, mensaje) => {
        e.stopPropagation();
        setInfoText(mensaje);
        setIsMoreInfoModalOpen(true);
    };

    const closeLicenseModal = () => setIsLicenseModalOpen(false);

    const closeMoreInfoModal = () => setIsMoreInfoModalOpen(false);

    useEffect(() => {
        const fetchTemperatureData = async () => {
            try {
                const today = new Date();
                //today.setDate(today.getDate() - 2);
                const startOfDay = new Date(today.setHours(0, 0, 0, 0)).toISOString();
                const endOfDay = new Date(today.setHours(23, 59, 59, 999)).toISOString();
                const data = await getTemperatureData(fieldId, 'H', startOfDay, endOfDay);

                // Transforma el objeto en un array de objetos con propiedades date y temperature
                const transformedData = Object.entries(data).map(([date, values]) => ({
                    date: date,
                    temperature: values.mean // Usa el valor 'mean' para la temperatura
                }));

                setTemperatureData(transformedData);
            } catch (error) {
                console.error('Error fetching temperature data:', error);
                setTemperatureData([]); // Establece un array vacío en caso de error
            }
        };

        if (model[0] === 'No model'){
            fetchTemperatureData();
        }
    }, [fieldId]);

    const formatDateChart = (date) => {
        const hour = String(date.getHours()).padStart(2, '0');
        return `${hour}`;
    };

    const temperatureChartData = {
        labels: temperatureData.map((entry) => formatDateChart(new Date(entry.date))),
        datasets: [
            {
                label: 'Temperature',
                data: temperatureData.map((entry) => entry.temperature),
                fill: false,
                borderColor: '#000000',
                pointBackgroundColor: '#000000',
                tension: 0.4,
                borderWidth: 2
            }
        ]
    };

    const temperatureChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false
            }
        },
        scales: {
            x: {
                grid: {
                    display: true
                },
                ticks: {
                    color: '#000000'
                }
            },
            y: {
                grid: {
                    display: false
                },
                ticks: {
                    color: '#000000'
                }
            }
        }
    };

    // Maneja el evento de clic en el componente. Si hay algún modal abierto, no se navega.
    const handleClick = () => {
        if (isAnyModalOpen) return;
        if (isAvailableLicense || isFields) {
            if (window.location.pathname !== `/field/${fieldId}`) {
                navigate(`/field/${fieldId}`);
            }
        }
    };

    const handleClickBuyLicense = () => {
        navigate('/addLicense', { state: { sensorNumber } });
    };

    const handleClickLinkSensor = () => {
        navigate('/link/sensor', { state: { fieldId } });
    };

    const getSignalIcon = (signalSensor) => {
        switch (signalSensor) {
            case 'well_signal':
                return well_signal;
            case 'half_signal':
                return half_signal;
            case 'low_signal':
                return low_signal;
            default:
                return not_signal;
        }
    };

    const getBatteryIcon = (batteryStatus) => {
        switch (batteryStatus) {
            case 'not_signal_battery':
                return not_signal_battery;
            case 'no_battery':
                return not_battery;
            case 'low_battery':
                return low_battery;
            case 'half_battery':
                return half_battery;
            default:
                return well_battery;
        }
    };

    const openSharedUsersModal = async () => {
        try {
            const response = await getGuests(fieldId);
            setSharedUsers(response.results);
            setIsSharedUsersModalOpen(true);
        } catch (error) {
            console.error("Error al obtener los usuarios compartidos:", error);
        }
    };

    const closeSharedUsersModal = () => {
        setIsSharedUsersModalOpen(false);
    };

    const openRejectSharedModal = async () => {
        try {
            setIsRejectSharedModalOpen(true);
        } catch (error) {
            console.error("Error al obtener los usuarios compartidos:", error);
        }
    };

    const closeRejectSharedModal = () => {
        setIsRejectSharedModalOpen(false);
    };

    const openDeleteFieldModal = () => {
        setIsDeleteFieldModalOpen(true);
    };

    const closeDeleteFieldModal = () => {
        setIsDeleteFieldModalOpen(false);
    };

    const isMobile = window.innerWidth < 700;
    const isSmallMobile = window.innerWidth < 375;

    // Definimos la función que renderiza el contenido del mensaje
    const renderLinkContent = (type) => {
        const mobileStyle = isMobile ? { lineHeight: '1.15', fontSize: '15px' } : {};

        const headerDiv = (
            <div className={`${type === undefined ? 'hidden' : 'flex'} justify-content-between align-items-center mb-2`}>
                <span className="text-black">{t(`field_card.${type}`)}</span>
                <span style={{ visibility: 'hidden' }} className="font-medium uppercase text-black">{t('field_card.wait')}</span>
            </div>
        );

        const handleLinkClick = (e) => {
            e.preventDefault();
            let modalContent;
            if (userEmailLogin === ownerEmail) {
                if (haveSensor) {
                    modalContent = (
                        <>
                            {isAvailableLicense
                                ? t('field_card.license_valid_model_text_owner')
                                : t('field_card.license_caducated_text_owner')}
                            <Link
                                to={`/linkSensor/${fieldId}`}
                                className="text-primary"
                                onClick={(e) => e.stopPropagation()}
                                style={mobileStyle}
                            >
                                {t('field_card.link_sensor_2_click')}
                            </Link>
                            {' '}{t('field_card.support_contact')}
                        </>
                    );
                } else {
                    modalContent = (
                        <>
                            {t('field_card.no_sensor_linked_owner')}{' '}
                            <Link
                                to={`/linkSensor/${fieldId}`}
                                className="text-primary"
                                onClick={(e) => e.stopPropagation()}
                                style={mobileStyle}
                            >
                                {t('field_card.link_sensor_1_click')}
                            </Link>
                            {' '}{t('field_card.support_contact')}
                        </>
                    );
                }
            } else {
                if (haveSensor) {
                    modalContent = isAvailableLicense
                        ? t('field_card.license_valid_model_text_shared')
                        : t('field_card.license_caducated_text_shared');
                } else {
                    modalContent = t('field_card.no_sensor_linked_shared');
                }
            }
            openMoreInfoModal(e, modalContent);
        };

        return (
            <>
                {headerDiv}
                <span style={mobileStyle}>{t('field_card.no_access_model')}</span>
                <Link
                    to="#"
                    className="text-primary"
                    style={mobileStyle}
                    onClick={handleLinkClick}
                >
                    {t('field_card.link_more_info')}
                </Link>
            </>
        );
    };

    return (
        <Card 
            className={`mt-3 ${!isAvailableLicense ? '' : 'cursor-pointer'}`}
            onClick={isAvailableLicense && !isAnyModalOpen ? handleClick : null}
            style={{ 
                width: isSmallMobile ? '95.6666%' : '91.6666%',
                backgroundColor: 'white', 
                borderRadius: '10px',
                border: 'solid',
                borderRadius: '10px',
                borderWidth: '1px',
                borderColor: '#DEE2E6',
                boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.3)',
                maxWidth: fieldsPerRow === 0 || fieldsPerRow === 1 ? '100%' : fieldsPerRow === 2 ? '48%' : fieldsPerRow === 3 ? '32%' : '24%',
            }}
        >
            <div className={`pb-0`} style={{padding: isSmallMobile ? '0.5rem' : '0.75rem'}} >
                <div className="flex justify-content-between align-items-center">
                    <div className="flex align-items-center">
                        <div className="flex align-items-center">
                            <Badge 
                                className="flex align-items-center text-sm bg-white border-round-3xl pl-0 text-black border-2 border-solid"
                                style={{ borderColor: '#DEE2E6', lineHeight: '1.5' }}
                                value={
                                    <>
                                        <span 
                                            style={{ 
                                                display: !haveSensor ? 'none' : 'block',
                                                width: '10px', 
                                                height: '10px', 
                                                borderRadius: '50%', 
                                                marginRight: '5px',
                                                marginLeft: '5px',
                                                backgroundColor: haveSensor && activeSensor === 'true' ? 'var(--primary-color)' : '#FF0000'
                                            }}
                                        ></span>
                                        <span style={{marginLeft: !haveSensor ? '5px' : '0px'}}>
                                            {haveSensor ? `${sensorNumber}` : sensorNumber}
                                        </span>
                                    </>
                                }
                            />
                            {haveSensor ? (
                                <span 
                                    onClick={(e) => openLicenseModal(e)}
                                    style={{ cursor: 'pointer', color: isAvailableLicense ? isWarningLicense ? '#F6894E' : 'var(--primary-color)' : 'red', fontSize: '14px', marginLeft: '5px', userSelect: 'none' }}
                                >
                                    {isAvailableLicense ? isWarningLicense ? 
                                        <>
                                            <u>{t('field_card.license')}</u> <FontAwesomeIcon icon={faWarning} /> 
                                        </> : 
                                        <>
                                            <u>{t('field_card.license')}</u> <FontAwesomeIcon icon={faCheck} /> 
                                        </> : 
                                        <>
                                            <u>{t('field_card.license')}</u> <FontAwesomeIcon icon={faTimes} />
                                        </>
                                    }
                                </span>
                            ) : (
                                <span 
                                    onClick={(e) => openLicenseModal(e)}
                                    style={{ cursor: 'pointer', color: 'red', fontSize: '14px', marginLeft: '5px', userSelect: 'none', display: 'none' }}
                                >
                                    <>
                                        <u>{t('field_card.license')}</u> <FontAwesomeIcon icon={faTimes} /> 
                                    </>
                                </span>
                            )}
                        </div>
                    </div>
                    {haveSensor && activeSensor === 'true' ? (
                        <div className="flex align-items-center">
                            <span className="flex align-items-center text-black text-sm" style={{ marginLeft: '5px' }}>
                                {t(`field_card.${signalSensor}`)}
                                <Image src={getSignalIcon(signalSensor)} alt="signal icon" style={{ marginLeft: '2px', display: 'flex' }} pt={{ image: { width: '18px', height: '18px' } }} />
                            </span>
                            <span className="flex align-items-center text-black text-sm" style={{ marginLeft: '5px' }}>
                                {batterySensor.value === -1 ? 'no_battery' : batterySensor.value}%
                                <Image src={getBatteryIcon(batterySensor.status)} alt="battery icon" style={{ marginLeft: '2px', display: 'flex' }} pt={{ image: { width: '18px', height: '18px' } }} />
                            </span>
                        </div>
                    ) : (
                        <div className="hidden"> {/*className="flex align-items-center"*/}
                            <span className="flex align-items-center text-black text-sm" style={{ marginLeft: '5px' }}>
                                {t(`field_card.${'no_signal'}`)}
                                <Image src={getSignalIcon('no_signal')} alt="signal icon" style={{ marginLeft: '2px', display: 'flex' }} pt={{ image: { width: '18px', height: '18px' } }} />
                            </span>
                            <span className="flex align-items-center text-black text-sm">
                                <Image src={getBatteryIcon('not_signal_battery')} alt="battery icon" style={{ marginLeft: '2px', display: 'flex' }} pt={{ image: { width: '18px', height: '18px' } }} />
                            </span>
                        </div>
                    )}
                </div>

                <div style={{
                    borderBottom: '1px solid #DEE2E6',
                    margin: isSmallMobile ? '0 -0.5rem' : '0 -0.75rem',
                    paddingTop: isSmallMobile ? '0.5rem' : '0.75rem'
                }}>

                </div>
                <div className="flex align-items-center justify-content-between"  style={{paddingTop: '4px'}}>
                    <h3 className="font-bold text-black mb-0 mt-0" style={{wordBreak: 'break-all', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                        {fieldName}
                    </h3>
                </div>
                
                <div className="flex align-items-center mt-0 mb-1">
                    <span className="font-medium text-color-secondary text-sm">
                        {t(`field.crops.${cropId}`)}
                        {model[0] === 'Nematode active' && isAvailableLicense && (
                            <>
                                {model[1] === 0 && ` - ${t('field_card.generation').charAt(0).toUpperCase() + t('field_card.generation').slice(1)} ${t('field_card.initial')}`}
                                {model[1] !== 0 && ` - ${model[1]}º ${t('field_card.generation')}`}
                            </>
                        )}
                    </span>
                </div>

                {model[0] === 'Nematode active' && (
                    <div style={{marginBottom: '0.75rem', marginTop: '0.75rem'}} >
                        {isAvailableLicense && license_info.nematode_ovicide ? (
                            <div className="mb-2">
                                <div className="flex justify-content-between align-items-center mb-2">
                                    <span 
                                        className={`${model[2] > model[5] ? 'font-medium' : ''} text-black`}
                                        style={model[3] === 'red' ? { animation: 'blinker 1s linear infinite' } : {}}
                                    >
                                        {t('field_card.egg')}
                                    </span>
                                    <span 
                                        className={`${model[2] > model[5] ? 'font-medium' : ''} uppercase text-sm text-black`}
                                        style={model[3] === 'red' ? { animation: 'blinker 1s linear infinite' } : {}}
                                    >
                                        {model[3] === 'blue' ? t('field_card.wait') : (model[3] === 'orange' ? <><FontAwesomeIcon icon={faExclamationTriangle} style={{ marginRight: '5px' }} />{t('field_card.prepare')}</> : (model[3] === 'red' ? <><FontAwesomeIcon icon={faExclamationTriangle} style={{ marginRight: '5px' }} />{t('field_card.treat')}</> : t('field_card.wait')))}
                                    </span>
                                </div>
                                <div style={{ padding: '3px', backgroundColor: "#DEE2E6", borderRadius: '50px' }}>
                                    <ProgressBar 
                                        value={model[2] < 12 ? 12 : model[2]} 
                                        color={getColor(model[3])}
                                        showValue={true}
                                        displayValueTemplate={() => <span style={{ fontSize: '14px', fontWeight: '500'}}>{`${model[2]} %`}</span>}
                                        style={{ borderRadius: '20px', height: '20px', backgroundColor: "#DEE2E6" }}
                                        pt={{
                                            value: {
                                                className: '',
                                                style: { backgroundColor: getColor(model[3]) }
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="relative mb-2">
                                <div className="m-0 w-full" style={{ minHeight: '26px', color: 'red' }}>
                                    {renderLinkContent('egg')}
                                </div>
                            </div>
                        )}
                        {isAvailableLicense && license_info.nematode_larvicide ? (
                            <div>
                                <div className="flex justify-content-between align-items-center mb-2">
                                    <span 
                                        className={`${model[5] > model[2] ? 'font-medium' : ''} text-black`}
                                        style={model[6] === 'red' ? { animation: 'blinker 1s linear infinite' } : {}}
                                    >
                                        {t('field_card.larvicide')}
                                    </span>
                                    <span 
                                        className={`${model[5] > model[2] ? 'font-medium' : ''} uppercase text-sm text-black`}
                                        style={model[6] === 'red' ? { animation: 'blinker 1s linear infinite' } : {}}
                                    >
                                        {model[6] === 'blue' ? t('field_card.wait') : (model[6] === 'orange' ? <><FontAwesomeIcon icon={faExclamationTriangle} style={{ marginRight: '5px' }} />{t('field_card.prepare')}</> : (model[6] === 'red' ? <><FontAwesomeIcon icon={faExclamationTriangle} style={{ marginRight: '5px' }} />{t('field_card.treat')}</> : t('field_card.wait')))}
                                    </span>
                                </div>
                                <div style={{ padding: '3px', backgroundColor: "#DEE2E6", borderRadius: '50px' }}>
                                    <ProgressBar 
                                        value={model[5] < 12 ? 12 : model[5]} 
                                        color={getColor(model[6])}
                                        showValue={true}
                                        displayValueTemplate={() => <span style={{ fontSize: '14px', fontWeight: '500'}}>{`${model[5]} %`}</span>}
                                        style={{ borderRadius: '20px', height: '20px', backgroundColor: "#DEE2E6" }}
                                        pt={{
                                            value: {
                                                className: '',
                                                style: { backgroundColor: getColor(model[6]) }
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="relative mb-2">
                                <div className="m-0 w-full" style={{ minHeight: '26px', color: 'red', display: 'none' }}>
                                    {renderLinkContent('larvicide')}
                                </div>
                            </div>
                        )}
                    </div>
                )}

                {model[0] === 'Solarization active' && (
                    isAvailableLicense && license_info.solarization ? (
                        <div style={{marginBottom: '0.75rem', marginTop: '0.75rem'}} >
                            {/* Encabezado con el título y valor actual */}
                            <div className="flex justify-content-between align-items-center mb-2">
                                <span className="font-medium text-black">{t('field_card.degrees')}</span>
                                <span className="font-medium uppercase text-black">{
                                    model[1] < 50 ? t('field_card.null') : 
                                    model[1] < 500 ? t('field_card.low') : 
                                    model[1] < 1500 ? t('field_card.moderate') : 
                                    model[1] < 3000 ? t('field_card.good') : 
                                    model[1] < 6000 ? t('field_card.excellent') :
                                    t('field_card.super')
                                }</span>
                            </div>
                            <div style={{ padding: '3px', backgroundColor: "#DEE2E6", borderRadius: '50px'}}>
                                <ProgressBar 
                                    value={model[1] < 100 ? 13 : 
                                        model[1] < 1000 ? 16 : 
                                        model[1] < 1333 ? 19 : 
                                        model[1] < 1500 ? 21 :
                                        model[1] < 1777 ? 23 :
                                        model[1] < 2000 ? 25 : 
                                        model[1] > 7000 ? 100 :
                                        (model[1] / 7000) * 100} 
                                    color={getColor(model[3])}
                                    showValue={true}
                                    displayValueTemplate={() => <span style={{ fontSize: '14px', fontWeight: '500', backgroundColor: getColorCard(model) }}>{`${model[1]} ºC`}</span>}
                                    style={{ borderRadius: '20px', height: '20px', backgroundColor: "#DEE2E6" }}
                                    pt={{
                                        value: {
                                            className: '',
                                            style: { backgroundColor: getColorCard(model) }
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    ) : (
                        <div style={{marginBottom: '0.75rem', marginTop: '0.75rem'}} className="relative">
                            <div className="relative mb-2">
                                <div className="m-0 w-full" style={{ minHeight: '26px', color: 'red' }}>
                                    {renderLinkContent('degrees')}
                                </div>
                            </div>
                        </div>
                    )
                )}

                {model[0] === 'No model' && (
                    isAvailableLicense && license_info.temperature ? (
                        temperatureData.length > 0 ? (
                            <div className="relative">
                                <Chart className="z-index-1 w-full" style={{marginTop: '5.8px', marginBottom: '5.8px'}} type="line" data={temperatureChartData} options={temperatureChartOptions} />
                            </div>
                        ) : (
                            <div className="relative">
                                <Chart className="z-index-1" style={{visibility: 'hidden', marginTop: '5.8px', marginBottom: '5.8px'}} type="line" data={temperatureChartData} options={temperatureChartOptions} />
                                <p className="text-black absolute m-0 w-full" style={{
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    lineHeight: isMobile ? '1.15' : '',
                                    fontSize: isMobile ? '15px' : ''
                                }}>
                                    {t('field_card.no_data_available')}
                                </p>
                            </div>
                        )
                    ) : (
                        userEmailLogin === ownerEmail ? (
                            <div className="relative">
                                <Chart className="z-index-1" style={{visibility: 'hidden', marginTop: '5.8px', marginBottom: '5.8px'}} type="line" data={temperatureChartData} options={temperatureChartOptions} />
                                <div className="absolute m-0 w-full" style={{
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    lineHeight: isMobile ? '1.15' : '',
                                    fontSize: isMobile ? '15px' : '',
                                    color: 'red'
                                }}>
                                    {renderLinkContent()}
                                </div>
                            </div>
                        ) : (
                            <div className="relative">
                                <Chart className="z-index-1" style={{visibility: 'hidden', marginTop: '5.8px', marginBottom: '5.8px'}} type="line" data={temperatureChartData} options={temperatureChartOptions} />
                                <p className="absolute m-0 w-full" style={{
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    lineHeight: isMobile ? '1.15' : '',
                                    fontSize: isMobile ? '15px' : '',
                                    color: 'red'
                                }}>
                                    {renderLinkContent()}
                                </p>
                            </div>
                        )
                    )
                )}

            </div>
            <div 
                className="p-3 pt-2 pb-2"
                style={{ backgroundColor: "#DEE2E6", borderRadius: '0 0 10px 10px' }}
            >
                <span 
                    className="text-sm m-0 text-black" 
                    style={{ wordWrap: 'break-word', overflowWrap: 'break-word'}}
                >
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <span 
                            style={{ 
                                wordBreak: 'break-all', 
                                whiteSpace: 'nowrap', 
                                overflow: 'hidden', 
                                textOverflow: 'ellipsis',
                                paddingRight: '10px',
                                userSelect: 'none'
                            }}
                        >
                            <FontAwesomeIcon style={{ marginRight: '5px' }} icon={faShareNodes} />
                            {userEmailLogin === ownerEmail ? t('field_card.you') : ownerEmail}
                        </span>
                        <div>
                            <span 
                                style={{ cursor: 'pointer', textDecoration: 'underline', whiteSpace: 'nowrap', userSelect: 'none' }} 
                                onClick={(e) => { 
                                    e.stopPropagation(); 
                                    userEmailLogin === ownerEmail ? openSharedUsersModal() : openRejectSharedModal(); 
                                }}
                            >
                                {t(userEmailLogin === ownerEmail ? 'field_card.link' : 'field_card.unlink')}
                            </span>
                            <span 
                                style={{ cursor: 'pointer', textDecoration: 'underline', whiteSpace: 'nowrap', marginLeft: '10px', display: userEmailLogin === ownerEmail ? '' : 'none', userSelect: 'none' }} 
                                onClick={(e) => { 
                                    e.stopPropagation(); 
                                    openDeleteFieldModal(); 
                                }}
                            >
                                {t('field_card.delete')}
                            </span>
                        </div>
                    </div>
                </span>
            </div>

            <ModalShared 
                isOpen={isSharedUsersModalOpen} 
                onClose={closeSharedUsersModal} 
                sharedUsers={sharedUsers}
                fieldId={fieldId}
                setSharedUsers={setSharedUsers}
            />

            <ModalRejectShared 
                isOpen={isRejectSharedModalOpen} 
                onClose={closeRejectSharedModal}
                fieldId={fieldId}
                userId={userId}
                setTrigger={setTrigger}
            />

            <ModalDeleteField
                isOpen={isDeleteFieldModalOpen}
                onClose={closeDeleteFieldModal}
                fieldId={fieldId}
            />

            <ModalLicenseExpiration 
                isOpen={isLicenseModalOpen}
                onClose={closeLicenseModal}
                fieldId={fieldId}
                isOwner={userEmailLogin === ownerEmail}
                licenseInfo={license_info}
            />

            <ModalMoreInfo 
                isOpen={isMoreInfoModalOpen}
                onClose={closeMoreInfoModal}
                info_text={info_text}
            />
        </Card>
    );
};