// index.js
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App.js';

const version = process.env.REACT_APP_VERSION;
const apiUrl = process.env.REACT_APP_API_URL;

const VersionChecker = ({ children }) => {
  useEffect(() => {
    const checkVersion = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/v1/version`);
        if (response.ok) {
          const data = await response.json();
          const backendVersion = String(data.pwa);
          if (version !== backendVersion) {
            window.location.reload();
          }
        }
      } catch (error) {
        console.error('Error verificando la versión:', error);
      }
    };

    //checkVersion();
  }, []);

  return children;
};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <VersionChecker>
    <App />
  </VersionChecker>
);
